import React, { useEffect, useState } from 'react';

import type { FC } from 'react';
import type { SubmitPasswordProps } from './SubmitPassword.types';
import { inputFieldStyles } from '../ForgetPassword.constants';
import { InputField } from '~components/InputField';
import { Loader } from '~components/Loader';

import './SubmitPassword.styles.scss';
import { AppRoutes, icons } from '~constants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

export const SubmitPassword: FC<SubmitPasswordProps> = (props) => {
    const {
        onChange,
        formData,
        formErrors,
        onForgetPasswordSubmit,
        isLoading,
        onResend,
    } = props;
    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPassword: false,
    });
    const [timer, setTimer] = useState(60);
    const [isResendDisabled, setIsResendDisabled] = useState(true);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleShowPasswordToggle = (key: 'password' | 'confirmPassword') => {
        setShowPassword(() => {
            return { ...showPassword, [key]: !showPassword[key] };
        });
    };

    const handleResend = () => {
        setIsResendDisabled(true);
        setTimer(60);
        onResend();
    };

    useEffect(() => {
        if (timer > 0) {
            const countdown = setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);

            return () => clearTimeout(countdown);
        } else {
            setIsResendDisabled(false);
        }
    }, [timer]);
    return (
        <Form
            onSubmit={onForgetPasswordSubmit}
            className="submit-password-section"
        >
            <div className="forget-password-fields-container">
                <div
                    className={`input-icon-container ${formErrors?.code ? 'red-border' : 'grey-border'} `}
                >
                    <InputField
                        name="code"
                        type="text"
                        label="Reset Code"
                        value={formData.code}
                        onChange={onChange}
                        placeholder="123456"
                        style={inputFieldStyles}
                        error={formErrors?.code}
                    />
                </div>

                <div
                    className={`input-icon-container ${formErrors?.password ? 'red-border' : 'grey-border'} `}
                >
                    <InputField
                        name="password"
                        type={showPassword.password ? 'text' : 'password'}
                        label={t('new_password')}
                        minLength={8}
                        value={formData.password}
                        onChange={onChange}
                        placeholder={t('password')}
                        style={inputFieldStyles}
                        error={formErrors?.password}
                    />
                    <img
                        src={icons.eyeIcon}
                        alt="eye"
                        onClick={() => handleShowPasswordToggle('password')}
                    />
                </div>
                <div
                    className={`input-icon-container ${formErrors?.confirmPassword ? 'red-border' : 'grey-border'} `}
                >
                    <InputField
                        name="confirmPassword"
                        type={
                            showPassword.confirmPassword ? 'text' : 'password'
                        }
                        label={t('confirm_password')}
                        minLength={8}
                        value={formData.confirmPassword || ''}
                        onChange={onChange}
                        placeholder={t('confirm_password')}
                        style={inputFieldStyles}
                        error={formErrors?.confirmPassword}
                    />
                    <img
                        src={icons.eyeIcon}
                        alt="eye"
                        onClick={() =>
                            handleShowPasswordToggle('confirmPassword')
                        }
                    />
                </div>
            </div>

            <div className="resend-code">
                {isResendDisabled ? (
                    <p>Wait {timer} seconds to receive the code</p>
                ) : (
                    <p>
                        {' '}
                        Didn't receive your reset code?
                        <span onClick={handleResend}>
                            {' '}
                            Click here to resend it
                        </span>{' '}
                    </p>
                )}
            </div>

            <div className="footer-btn">
                <button type="submit" className="forgot-cta-btn">
                    {isLoading ? <Loader color="#fff" /> : <span>Submit</span>}
                </button>
                <button
                    onClick={() => navigate(AppRoutes.Login)}
                    className="forgot-cta-btn-outlined"
                >
                    {'Back'}
                </button>
            </div>
        </Form>
    );
};

import React from 'react';
import BlurBackdropPopup from '~components/Modals/BlurBackdropPopup/BlurBackdropPopup';
import './QrCodePopup.styles.scss';
import { Assets } from '~constants';
import QRCode from '~components/QRCode/QRCode';
interface QrCodePopupProps {
    showPopup: boolean;
    handleClose: () => void;
    redirectUrl:string;
}

const QrCodePopup: React.FC<QrCodePopupProps> = ({
    showPopup,
    handleClose,
    redirectUrl
}) => {
    return (
        <BlurBackdropPopup
            customClass="qr-code-popup-width"
            show={showPopup}
            handleClose={handleClose}
        >
            <div className="qr-code-container">
                <div className="qr-code-header">
                    <h1>Scan to download The app</h1>
                    <p>Available on app store and play store</p>
                </div>
                <div className="qr-code-image">
                   <QRCode redirectUrl={redirectUrl} size={260} />
                </div>
                <button onClick={handleClose} className="close-popup">
                    Close
                </button>
            </div>
        </BlurBackdropPopup>
    );
};

export default QrCodePopup;

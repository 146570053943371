import React from 'react';
import BlurBackdropPopup from '~components/Modals/BlurBackdropPopup/BlurBackdropPopup';
import './SemesterSubscriptionPopup.styles.scss';
import { Assets, icons } from '~constants';
import { useScreenSize } from '~hooks';
interface SemesterSubscriptionPopupProps {
    showPopup: boolean;
    handleClose: () => void;
}

const SemesterSubscriptionPopup: React.FC<SemesterSubscriptionPopupProps> = ({
    showPopup,
    handleClose,
}) => {
    const { isMobile } = useScreenSize();

    return (
        <BlurBackdropPopup
            customClass="semester-popup"
            show={showPopup}
            handleClose={handleClose}
        >
            <div className="semester-container">
                <h1 className="popup-title">
                <img src={icons.partyPopper} /> You’ve Upgraded to the Semester Plan!
                </h1>
                <p className="popup-sub-title">
                    You’ve saved <span>$32 </span> and now have{' '}
                    <span> unlimited access </span> to StudyBuddy for the next 3
                    months! Here’s what’s included:
                </p>

                <div className="info">
                    <p><img src={icons.rocket} /><span>Unlimited Access to {!isMobile && 'all'} StudyBuddy</span></p>
                    <p><img src={icons.chart} /><span>Enhanced Productivity</span></p>
                    <p><img src={icons.speechBalloon} /><span>Priority Support</span></p>
                </div>

                <button onClick={handleClose} className="close-sem-popup">
                    Continue
                </button>
            </div>
        </BlurBackdropPopup>
    );
};

export default SemesterSubscriptionPopup;

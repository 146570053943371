import React, { FC, useState, useEffect, useMemo } from 'react';
import './Steps.styles.scss';
import { toneOfVoice } from '../WriterSteps.constants';
import Slider from '~components/Slider/Slider';
import { postWithAuth } from '~utils/apiUtil';
import { useAuth, useScreenSize } from '~hooks';
import { TITLE_GENERATOR_URL } from '~constants/urls';
import { useTranslation } from 'react-i18next';
import { WriterLoader } from '~components/Loader';
import { extractTitles, getWordCount } from '~utils';

type StepsProps = {
    steps: number;
    topic?: string;
    long?: number;
    tone?: string;
    title?: string |null;
    setTone: (value: string) => void;
    setTitle: (value: string) => void;
    setLong: (value: number) => void;
    setTopic: (value: string) => void;
};

export const Steps: FC<StepsProps> = (props) => {
    const { steps, topic, setTopic, long, setLong, tone, setTone, title,setTitle } = props;

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isLoadingTitle, setIsLoadingTitle] = useState(false);
    const [selectedTone, setSelectedTone] = useState<string | null>(null);
    // const [title, setTitle] = useState<string | null>(null); // State to store the title

    const { token } = useAuth();
    const { t } = useTranslation();

    const sliderItems = [
        {
            label: 'Short',
            value: 1,
            subHead: '(~1-2 pages)',
            symbols: '1200 - 2400',
            words: '300-500',
        },
        {
            label: 'Medium',
            value: 3,
            subHead: '(~3-5 pages)',
            symbols: '3600 - 6000',
            words: '600-1200',
        },
        {
            label: 'Long',
            value: 9,
            subHead: '(~7-12 pages)',
            symbols: '8400 - 14400',
            words: '1500-300',
        },
        {
            label: 'Very long',
            value: 20,
            subHead: '(~15-25 pages)',
            symbols: '18000 - 30000',
            words: '4000-5000',
        },
    ];

    const handleSliderChange = (index: number) => {
        setSelectedIndex(index);
        const selectedValue = sliderItems[index].value;
        setLong(selectedValue);
    };

    const handleToneClick = (toneName: string) => {
        setSelectedTone(toneName);
        setTone(toneName);
    };

    const getTitleHeaders = async (): Promise<void> => {
        setIsLoadingTitle(true)
        try {
            const generateTitle = await postWithAuth(
                TITLE_GENERATOR_URL,
                { topic: topic, length: (long || 1) * 300},
                token as string,
            );
            const titleContent =
                generateTitle?.data?.data?.choices[0].message.content;
            setTitle(titleContent); // Set the title state with the response
        } catch (error) {
            console.error('Error fetching title:', error);
        } finally {
            setIsLoadingTitle(false);
        }
    };

    const promptStreangthTitle = useMemo(() => {
        const promptStrength = getWordCount(topic || '');

        if (promptStrength > 5 && promptStrength < 10) {
            return 'writer.medium_prompt';
        }
        if (promptStrength > 9) {
            return 'writer.strong_prompt';
        }
        return 'writer.weak_prompt';
    }, [topic]);

    const promptStreangthText = useMemo(() => {
        const promptStrength = getWordCount(topic || '');

        if (promptStrength > 5 && promptStrength < 10) {
            return 'writer.add_key_term_detail_desc';
        }
        if (promptStrength > 9) {
            return 'writer.refer_to_info';
        }
        return 'writer.add_more_detail_desc';
    }, [topic]);

    useEffect(() => {
        if (steps === 4) {
            getTitleHeaders(); // Fetch title when steps === 4
        }
    }, [steps]); // Only call this when `steps` changes

    return (
        <div className="steps-container">
            {steps === 1 && (
                <div className="first-step-content">
                    <textarea
                        value={topic}
                        placeholder="Type your topic here"
                        onChange={(e) => setTopic(e.target.value)}
                    />
                    <div className={`prompt-progress prompt-progress--${getWordCount(topic || '')}`}></div>
                    <p>
                        <strong>{t(promptStreangthTitle)}:</strong>{' '}
                        {t(promptStreangthText)}
                    </p>
                </div>
            )}
            {steps === 2 && (
                <div className="second-step-content">
                    <div className="slider-container">
                        <Slider
                            items={sliderItems}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={handleSliderChange}
                        />
                    </div>
                    <p>
                        {`Approx.${sliderItems[selectedIndex].symbols} symbols / ${sliderItems[selectedIndex].words} words`}
                    </p>
                </div>
            )}
            {steps === 3 && (
                <div className="third-step-content">
                    <div className="tone-of-voice-container">
                        {toneOfVoice.map((tone) => (
                            <div
                                key={tone.id}
                                className={`tone-of-voice ${selectedTone === tone.name ? 'active' : ''}`}
                                onClick={() => handleToneClick(tone.name)}
                            >
                                <p>{tone.name}</p>
                            </div>
                        ))}
                    </div>

                    <p
                        className={`third-step-info  ${!selectedTone ? 'active' : ''} `}
                    >
                        Please select an option
                    </p>
                </div>
            )}
            {steps === 4 && (
                <div className="fourth-step-content">
                    {!isLoadingTitle && title ? (
                        <ol>
                            {title.split('\n').map((line, index) => (
                                <li className="title-item">
                                    <strong>{line}</strong>
                                </li>
                            ))}
                        </ol>
                    ) : (
                        <WriterLoader />
                    )}
                </div>
            )}
        </div>
    );
};

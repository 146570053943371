import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { postWithAuth } from '~utils/apiUtil';
import { useAuth, useSnackbar } from '~hooks';
import './WriterContentGenerator.styles.scss';
import { GENERATE_CONTENT_URL, REWRITE_URL } from '~constants/urls';
import ContentGeneratorHeader from './ContentGeneratorHeader/ContentGeneratorHeader';
import { ContentTextArea } from '~components/ContentTextArea/ContentTextArea';
import { ReactComponent as NinjaIcon } from '~/assets/ninja-icon.svg';
import { ReactComponent as CustomizeIcon } from '~/assets/customize.svg';
import { ReactComponent as QuotesIcon } from '~/assets/quotes.svg';
import { ReactComponent as FilterIcon } from '~/assets/filter.svg';
import { ReactComponent as SwapIcon } from '~/assets/swap.svg';
import { ReactComponent as ExpandIcon } from '~/assets/expand.svg';
import { ReactComponent as ShortenIcon } from '~/assets/shorten.svg';
import { useTranslation } from 'react-i18next';
import { AppRoutes, defaultErrorMessage } from '~constants';
import { WriterLoader } from '~components/Loader';
import { ExportModal } from './Export/Export';
import { getWordCount } from '~utils';
import { exportBasedOnType, ExportFormat } from '~utils/export';
import { ContentGeneratorLoader } from './ContentGeneratorLoader/ContentGeneratorLoader';
import { WriterSteps } from '../WriterSteps';

type DataParamsType = {
    topic: string;
    length: number;
    themePick: string;
};

export const WriterContentgenerator = () => {
    const [data, setData] = useState('');
    const [show, setShow] = useState(false);
    const [dataParams, setDataParams] = useState<DataParamsType>();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [isRewriteLoading, setIsRewriteLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isShowExport, setIsShowExport] = useState(false);
    const { token } = useAuth();
    const { t } = useTranslation();
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const topic = dataParams?.topic;
    const navigate = useNavigate();
    const { errorSnackbar } = useSnackbar();

    const onHumanize = () => {
        navigate(AppRoutes.WriterAITextHumanizer, {
            state: { payloadText: data },
        });
    };
    const handleClose = () => {
        navigate(-1);
    };

    const handleCTA = () => {
        console.log('handleCTA called >>>');
    };

    const onRewrite = async (type: string) => {
        setIsRewriteLoading(true);
        const rewriteParams = {
            generatedText: data,
            requestType: type,
        };
        try {
            const response = await postWithAuth(
                REWRITE_URL,
                rewriteParams,
                token as string,
            );
            if (response.status === 200) {
                const formattedText =
                    response?.data?.data?.choices[0]?.message?.content
                        .replace(/\\n/g, '\n') // Replace \n with actual new lines
                        .replace(/"/g, ''); // Remove double quotes if needed
                setData(formattedText); // replace with new data
            }
        } catch (err: any) {
            // setError(err as any);
            errorSnackbar(err.message || defaultErrorMessage);
        } finally {
            setIsRewriteLoading(false);
        }
    };

    const onExport = () => {
        setIsShowExport(true);
    };
    const fetchData = async (data: DataParamsType) => {
        setDataParams(data);
        setLoading(true);
        setShow(false);
        try {
            const response = await postWithAuth(
                GENERATE_CONTENT_URL,
                data,
                token as string,
            );
            if (response.status === 200) {
                const formattedText =
                    response?.data?.data?.choices[0]?.message?.content
                        .replace(/\\n/g, '\n') // Replace \n with actual new lines
                        .replace(/"/g, ''); // Remove double quotes if needed
                setData((prevData) => prevData + formattedText); // Append new data
            }
        } catch (err: any) {
            setError(err);
            errorSnackbar(err.message || defaultErrorMessage);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (dataParams) {
            // Ensure params are available before making the request
            // fetchData();
            setLoading(true);
            setShow(false);
        } else {
            setShow(!data);
        }
    }, [dataParams, token]); // Dependency array to refetch if parameters change

    // Scroll the textarea to the bottom when new data is added
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        }
    }, [data]); // Trigger on data change

    if (error)
        return (
            <>
                <header>
                    <h1 className="dashboard-title">
                        👋 {t('writer.heading')}
                    </h1>
                </header>
                <p> {t('error_try_again')} </p>
            </>
        );

    const handleExportClick = (format: ExportFormat) => {
        exportBasedOnType(data, format);
        setIsShowExport(false);
    };

    return (
        <>
            <header>
                <h1 className="dashboard-title">{topic}</h1>
            </header>
            <div className="content-generator-container">
                <ContentGeneratorHeader
                    onHumanize={onHumanize}
                    onRewrite={() => onRewrite('default')}
                    onExport={onExport}
                    wordsCount={getWordCount(data)}
                />

                <div className="content-humanizer-parent main">
                    <div className="content-humanizer-header">
                        <h2>{topic}</h2>
                    </div>
                    <div className="content-humanizer-body">
                        {isRewriteLoading ? (
                            <WriterLoader />
                        ) : (
                            <ContentTextArea
                                onTextChange={setData}
                                isInDetectMode={true}
                                isHighlighted={true}
                                text={data}
                                isEditing={isEditing} 
                                setIsEditing={setIsEditing}
                            />
                        )}
                    </div>
                    <div className="content-humanizer-footer">
                        <button className="humanizeButton" onClick={onHumanize}>
                            <NinjaIcon />
                            {t('writer.humanize_button')}
                        </button>
                        <button
                            className="white-button"
                            onClick={() => onRewrite('customize')}
                        >
                            <CustomizeIcon />
                            {t('writer.customize')}
                        </button>
                        <button
                            className="white-button"
                            onClick={() => onRewrite('cite')}
                        >
                            <QuotesIcon />
                            {t('writer.cite')}
                        </button>
                        <button
                            className="white-button"
                            onClick={() => onRewrite('personalizer')}
                        >
                            <FilterIcon />
                            {t('writer.personalize')}{' '}
                        </button>
                        <button
                            className="white-button"
                            onClick={() => onRewrite('paraphrase')}
                        >
                            <SwapIcon />
                            {t('writer.paraphrase')}{' '}
                        </button>
                        <button
                            className="white-button"
                            onClick={() => onRewrite('expand')}
                        >
                            <ExpandIcon />
                            {t('writer.expand')}{' '}
                        </button>
                        <button
                            className="white-button"
                            onClick={() => onRewrite('shorten')}
                        >
                            <ShortenIcon />
                            {t('writer.shorten')}{' '}
                        </button>
                    </div>
                </div>
            </div>
            <ExportModal
                show={isShowExport}
                handleClose={() => {
                    setIsShowExport(false);
                }}
                handleExportClick={handleExportClick}
            />
            <ContentGeneratorLoader show={loading} />
            <WriterSteps
                show={show}
                handleClose={handleClose}
                handleCTA={handleCTA}
                onGenerageContent={fetchData}
            />
        </>
    );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import './WriterTextHumanizer.styles.scss';
import { ReactComponent as NinjaIcon } from '~/assets/ninja-icon.svg';
import scannerIcon from '~/assets/scan-icon.svg';

import { ContentTextArea } from '~components/ContentTextArea/ContentTextArea';
import ContentEditableDiv from '~components/ContentTextArea/ContentEditableDiv';
import CheckForAi from './CheckForAi/CheckForAi';
import { Loader } from '~components/Loader';
import HumanzedResultContent from './HumanzedResultContent';
import { useManageTextHumanizer } from './hooks';

export const WriterTextHumanizer: React.FC = () => {
    const { t } = useTranslation();

    const {
        // memoized word count for `text``
        wordCount,

        // methods for managing text
        handleHumanizeText,
        handleAIDetection,
        isCheckedTextDetecting,

        // text states
        text,
        humanizedText,
        checkedText,

        // ai detect response for check ai modal
        aiDetectionResponse,

        // state setters
        setAiCheckState,
        setText,

        // boolean states
        isHumanizing,
        isAIChecking,
        showPopup,
        isDetected,
        isEditing,
        setIsEditing
    } = useManageTextHumanizer();

    return (
        <>
            <header>
                <h1 className="dashboard-title">
                    {t('writer.ai_text_humanizer')}
                </h1>
            </header>
            <div className="aiTextHumanizerParent main">
                <span className="textHumanizerHeader">
                    {t('writer.waiting_for_input')}
                </span>
                <div className="aiTextHumanizerInnerContainer">
                    <div className="textHumanizerLeftContainer aiTextHumanizerItem" onClick={()=>setIsEditing(true)}>
                        <ContentTextArea text={text} onTextChange={setText} isEditing={isEditing} setIsEditing={setIsEditing}/>
                        <div className="textHumanizerLeftContainerBottom">
                            <div className="wordCount">{`${wordCount} ${t('writer.words')}`}</div>
                            <div className="humanizeText">
                                <button
                                    className="humanizeButton"
                                    onClick={() => handleHumanizeText(text)}
                                    disabled={isHumanizing || isAIChecking}
                                >
                                    {isHumanizing ? (
                                        <Loader color="#000" />
                                    ) : (
                                        <>
                                            <NinjaIcon />
                                            {t('writer.humanize_button')}
                                        </>
                                    )}
                                </button>
                                <button
                                    className="aiButton"
                                    onClick={() => handleAIDetection(text)}
                                    disabled={
                                        !wordCount ||
                                        isHumanizing ||
                                        isAIChecking
                                    }
                                >
                                    {isCheckedTextDetecting(text) ? (
                                        <Loader color="#000" />
                                    ) : (
                                        <>
                                            <img
                                                src={scannerIcon}
                                                height="16px"
                                                width="16px"
                                                alt="scanner icon"
                                            />
                                            {t('writer.check_for_ai')}
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="textHumanizerRightContainer aiTextHumanizerItem">
                        {humanizedText ? (
                            <ContentEditableDiv text={humanizedText} />
                        ) : (
                            <p className="ai-text-header">
                                {t('writer.humanize_content_placeholder')}
                            </p>
                        )}
                        <HumanzedResultContent
                            text={humanizedText}
                            isAIcheckLoading={isCheckedTextDetecting(
                                humanizedText,
                            )}
                            fetchAIDetection={() =>
                                handleAIDetection(humanizedText)
                            }
                            isHumanizing={isHumanizing}
                        />
                    </div>
                </div>
                <CheckForAi
                    handleClose={() =>
                        setAiCheckState((prev) => ({
                            ...prev,
                            showPopup: false,
                        }))
                    }
                    showPopup={showPopup}
                    onTextUpdate={setText}
                    text={checkedText}
                    onHumanizeText={() => {
                        handleHumanizeText(checkedText);
                        setAiCheckState((prev) => ({
                            ...prev,
                            showPopup: false,
                        }));
                    }}
                    isHighlighted={isDetected}
                    aiDetectionResponse={aiDetectionResponse}
                />
            </div>
        </>
    );
};

import { Route, Routes as ReactRouterRoutes, Navigate } from 'react-router-dom';

import { AppRoutes } from '~constants';
import PrivateRoutes from './PrivateRoutes';
import { Login } from '~pages/Login';
import { Register } from '~pages/Register';
import { Dashboard } from '~pages/Dashboard';
import { DashboardProfile } from '~pages/Dashboard/DashboardProfile';
import { DashboardContact } from '~pages/Dashboard/DashboardContact';
import { PaymentDetails } from '~pages/PaymentDetails';
import { ForgetPassword } from '~pages/ForgetPassword';
import { Homepage } from '~pages/Homepage';
import { NewHomepage } from '~pages/New_Homepage';
import { Privacy } from '~pages/Privacy';
import { Terms } from '~pages/Terms';
import { WriterDashboard } from '~pages/WriterDashboard';
import { WriterContentgenerator } from '~pages/WriterDashboard/ContentGenerator/WriterContentGenerator';
import { WriterDashboardWrapper } from '~pages/WriterDashboard/Layout/WriterDashboardWrapper';
import { WriterTextHumanizer } from '~pages/WriterDashboard/AIHumanizer/WriterTextHumanizer';
import { WriterDocument } from '~pages/WriterDashboard/Documents/WriterDocument';
import { WriterAIDetector } from '~pages/WriterDashboard/AiDetector/WriterAIDetector';
import { WriterTool } from '~pages/WriterTool';
import AppRedirect from '~pages/AppRedirect/Appredirect';
import { AccountDelete } from '~pages/AccountDelete';

export const Routes = () => {
    return (
        <ReactRouterRoutes>
            <Route element={<Dashboard />} path={AppRoutes.Dashboard}>
                <Route path="profile" element={<DashboardProfile />} />
                <Route path="contact" element={<DashboardContact />} />
            </Route>
            <Route element={<PrivateRoutes />}>
                <Route
                    path={AppRoutes.WriterSteps}
                    element={<WriterDashboardWrapper />}
                >
                    <Route index element={<WriterDashboard />} />
                    <Route
                        path={AppRoutes.WriterContentgenerator}
                        element={<WriterContentgenerator />}
                    />
                    <Route
                        path={AppRoutes.WriterAITextHumanizer}
                        element={<WriterTextHumanizer />}
                    />
                    <Route
                        path={AppRoutes.WriterAIDetector}
                        element={<WriterAIDetector />}
                    />
                    <Route
                        path={AppRoutes.WriterDocuments}
                        element={<WriterDocument />}
                    />
                </Route>
            </Route>
            <Route
                path={AppRoutes.Profile}
                element={<Navigate to={AppRoutes.Dashboard} replace />}
            />
            <Route path="*" element={<Navigate to={AppRoutes.Home} />} />

            <Route path={AppRoutes.Home} element={<Homepage />} />
            <Route path={AppRoutes.WriterTool} element={<WriterTool />} />
            <Route path={AppRoutes.AlternativePage}>
                <Route index element={<NewHomepage />} />
                <Route path=":id" element={<NewHomepage />} />
            </Route>
            <Route path={AppRoutes.Privacy} element={<Privacy />} />
            <Route path={AppRoutes.Terms} element={<Terms />} />
            <Route
                path={AppRoutes.Signin_Redirect}
                element={<PaymentDetails />}
            />
            <Route
                path={AppRoutes.Mobile_Checout_Redirect}
                element={<PaymentDetails />}
            />
            <Route path={AppRoutes.Signout_Redirect} element={<Login />} />
            <Route element={<Login />} path={AppRoutes.Login} />
            <Route element={<Register />} path={AppRoutes.Register} />
            <Route element={<AccountDelete />} path={AppRoutes.AccountDelete} />
            <Route
                element={<ForgetPassword />}
                path={AppRoutes.ForgetPassword}
            />
            <Route element={<AppRedirect />} path={AppRoutes.AppRedirect} />
        </ReactRouterRoutes>
    );
};

import { Modal } from 'react-bootstrap';

import './BlurBackdropPopup.styles.scss';
import { WatchDirectoryKind } from 'typescript';

interface BlurBackdropPopupProps {
    show: boolean;
    handleClose: () => void;
    children: React.ReactNode;
    customClass?: string;
    dialogClassName?: string;
}

const BlurBackdropPopup: React.FC<BlurBackdropPopupProps> = ({
    show,
    handleClose,
    children,
    customClass,
    dialogClassName
}) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName={`${dialogClassName || ''} blurModal`}
            contentClassName={customClass}
            size="xl"
            centered
        >
            <Modal.Body>{children}</Modal.Body>
        </Modal>
    );
};

export default BlurBackdropPopup;

import { ContentTextArea } from '~components/ContentTextArea/ContentTextArea';

import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './AiDetectorContent.styles.scss';
import { AiDetectorHeader } from './AiDetectorHeader/AIDetectorHeader';
import AiDetectorFooter from './AiDetectorFooter/AiDetectorFooter';
import { postWithAuth } from '~utils/apiUtil';
import { AI_DETECTOR_URL, PARAPHRASE_CONTENT_RETRIVE_URL, PARAPHRASE_CONTENT_URL } from '~constants/urls';
import { useAuth, useSnackbar } from '~hooks';
import { delay, getWordCount } from '~utils';
import { useNavigate } from 'react-router-dom';
import { defaultErrorMessage } from '~constants';

export interface AiDetectionResponse {
    AI: number;
    Chunks: string[];
    msg: number;
    Label: string;
    Probability: number;
    original: string;
}


export const WriterAIDetector = () => {
    const [text, setText] = useState('');
    const [isInDetectMode, setIsInDetectMode] = useState(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isDetected, setIsDetected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [aiDetectionResponse, setAiDetectionResponse] =
        useState<AiDetectionResponse>();

    const { token } = useAuth();
    const { errorSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const navigate = useNavigate();


    const status = useMemo(() => {
        const humanPercent = aiDetectionResponse?.msg;
        if (humanPercent === undefined) return 'NONE';

        if (humanPercent > 70) {
            return 'UNDETECTED';
        } else if (humanPercent > 50) {
            return 'WARNING';
        } else {
            return 'DETECTED';
        }
    }, [aiDetectionResponse]);

    const percentage = useMemo(() => {
        if (!isInDetectMode) {
            return undefined;
        }

        if (isDetected && aiDetectionResponse?.msg !== undefined) {
            const formattedProbability =
                Math.round(100 -aiDetectionResponse.msg);
            return formattedProbability;
        }

        return 0;
    }, [isDetected, isInDetectMode, aiDetectionResponse]);

    function handleHeaderButtonAction(button: string) {
        // const sanitizedText = text?.replaceAll('\n', ' ');
        switch (button) {
            case 'Detect AI':
                fetchAIDetection(text);
                break;

            case 'Humanize All Text':
                fetchHumanizeText(text);
                break;
        }
    }

    const fetchHumanizeText = async (payloadText: string) => {
        navigate('/writer-steps/AI-text-humanizer', { state: { payloadText } });

    };

    const fetchAIDetection = async (payloadText: string) => {
        const sanitizedText = text?.replaceAll('\n', ' ');
        if (getWordCount(text) < 50) {
            errorSnackbar('Please enter at least 50 words.');
            return;
        }

        setIsLoading(true);

        try {
            const response = await postWithAuth(
                AI_DETECTOR_URL,
                { textContent: sanitizedText },
                token as string,
            );
            console.log(response.data)
            const data = response.data;
            setIsLoading(false);
            setIsInDetectMode(true);
            setAiDetectionResponse(data);
            // handle text highlighting
            if (data?.msg < 70) {
                setIsDetected(true);
            } else {
                setIsDetected(false);
            }
            setText(payloadText)
        } catch (error:any) {
            setIsLoading(false);
            errorSnackbar(error?.response?.data?.msg || defaultErrorMessage);
        }
    };

    return (
        <>
            <header>
                <h1 className="dashboard-title"> {t('writer.ai_detector')} </h1>
            </header>
            <div className="ai-detector-container">
                <AiDetectorHeader
                    text={text}
                    isInDetectMode={isInDetectMode}
                    setIsInDetectMode={setIsInDetectMode}
                    setText={setText}
                    handleAction={handleHeaderButtonAction}
                    isLoading={isLoading}
                />
                <div className="ai-detector-body" onClick={()=>setIsEditing(true)} >
                    <ContentTextArea
                        isInDetectMode={isInDetectMode}
                        onTextChange={setText}
                        text={text}
                        gapClass="gap-24"
                        isHighlighted={isDetected}
                        aiDetectionResponse={aiDetectionResponse}
                        isEditing={isEditing} 
                        setIsEditing={setIsEditing}
                    />
                    <div></div>
                </div>
                <AiDetectorFooter status={status} percentage={percentage} />
            </div>
        </>
    );
};

export const inputFieldStyles = {
    labelFontSize: '14px',
    labelMarginBottom: '4px',
    labelColor: '#0E153A',
    height: '52px',
    fontSize: '14px',
    marginBottom: '8px',
    color: '#0E153A',
    padding: '14px 20px',
    borderRadius: '10px',
    border: '1px solid #EBEBEB',
    background: '#fff',
    boxShadow: '0px 0px 12px 2px rgba(255, 255, 255, 0.04) inset',
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import copyText from '~/assets/copy-text.svg';
import { Loader } from '~components/Loader';
import { useSnackbar } from '~hooks';
import scannerIcon from '~/assets/scan-icon.svg';
import { getWordCount } from '~utils';

interface HumanzedResultContentProps {
    text: string;
    isHumanizing?: boolean;
    isAIcheckLoading?: boolean;
    fetchAIDetection?: () => void;
}

export default function HumanzedResultContent({
    text,
    isAIcheckLoading,
    fetchAIDetection,
    isHumanizing,
}: Readonly<HumanzedResultContentProps>) {
    const { t } = useTranslation();
    const { successSnackbar } = useSnackbar();

    const copyTextToClipboard = () => {
        if (navigator.clipboard) {
            navigator.clipboard
                .writeText(text)
                .then(() => successSnackbar('Text copied to clipboard'))
                .catch((err) => console.error('Failed to copy text: ', err));
        } else {
            console.warn('Clipboard API not supported');
        }
    };

    const hasText = Boolean(text && text?.trim());
    return (
        <div className="humanizeResultText">
            <div className="wordCount">
                {' '}
                {text
                    ? getWordCount(text)
                    : 0}{' '}
                {t('writer.words')}
            </div>
            <div className="humanizedResultRightButtons">
                {hasText ? (
                    <button
                        className="aiButton"
                        onClick={() => fetchAIDetection?.()}
                        disabled={isHumanizing || isAIcheckLoading}
                    >
                        {isAIcheckLoading ? (
                            <Loader color="#000" />
                        ) : (
                            <>
                                <img
                                    src={scannerIcon}
                                    height="16px"
                                    width="16px"
                                    alt="not found "
                                />
                                {t('writer.check_result_for_ai')}
                            </>
                        )}
                    </button>
                ) : null}
                <div className="copyText" onClick={copyTextToClipboard}>
                    <img
                        src={copyText}
                        alt="copytext"
                        width="16px"
                        height="16px"
                    />
                    {t('copy')}
                </div>
            </div>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { CHROME_STORE_EXTENSION_URL, CK_ID, icons } from '~constants';
import { useAuth, useScreenSize, useSnackbar } from '~hooks';
import { useTranslation } from 'react-i18next';
import {
    formatDaysRemaining,
    formatPaymentStatus,
    getStripeUserDetails,
    isLifetime,
    loadCardDetails,
} from '../../PaymentPlan/PaymentPlan.functions';
import type {
    BillingDetails,
    DefaultPaymentDetails,
} from '../../PaymentPlan/PaymentPlan.types';
import {
    getChurnkeyHash,
    newCancellation,
    submitCancellationEvents,
} from '~components/CancellationFlow/CancellationFlow.functions';
import {
    getCardImage,
    getChurnkeyEnvironment,
    getDashboardCardImage,
    getRenewDate,
    logger,
} from '~utils';

import CardIcon from '../../../assets/credit-card.svg';
import bookIcon from '../../../assets/book-icon.svg';
import PlayIcon from '../../../assets/play.svg';
import extensionPreview from '../../../assets/extension-preview.svg';
import extensionPreviewMobile from '../../../assets/extension-preview-mobile.svg';
import { UserInfo } from '~components/UserInfo';
import UpgradeSemester, { ArrowUp } from '../UpgradeSemester/UpgradeSemester';
import './DashboardUserInfo.styles.scss';
import '../DashboardKnowledges/DashboardKnowledges.styles.scss';
import { ExtensionIcon } from '../DashboardKnowledges';
import { WatchTutorialPopup } from '~components/Modals/WatchTutorialPopup';
import { isUserHaveActiveSubscription, isUserSubscribed } from '~pages/Dashboard/Dashboard.function';

type DashboardKnowledgesProps = {
    showOnboarding: boolean;
    setShowOnboarding: (show: boolean) => void;
};

const DashboardUserInfo = (props: DashboardKnowledgesProps) => {
    const { showOnboarding, setShowOnboarding } = props;
    const [customerId, setCustomerId] = useState<string>('');
    const [isShowTutorialPopUp, setIsShowTutorialPopUp] = useState(false);
    const [defaultPaymentDetails, setDefaultPaymentDetails] =
        useState<DefaultPaymentDetails | null>(null);
    const [billingDetails, setBillingDetails] = useState<BillingDetails | null>(
        null,
    );
    console.log('billing details in line 52 ---->', billingDetails);

    const { user, token, planStatus } = useAuth();
    const { errorSnackbar, infoSnackbar, successSnackbar } = useSnackbar();

    const isActive = billingDetails && billingDetails.status === 'active';

    const { t } = useTranslation();
    const { isMobile } = useScreenSize();

    const onWatchTutorialClickHandler = () => {
        /* setIsShowTutorialPopUp(true); */
        window.open(CHROME_STORE_EXTENSION_URL, '_blank');
    };

    const planName =
        billingDetails && isLifetime(billingDetails)
            ? 'Lifetime'
            : billingDetails && billingDetails?.planNickName
              ? billingDetails?.planNickName
              : 'Basic Plan';

    console.log('plan name ------->', planName);

    const planStatusMsg =
        billingDetails && isLifetime(billingDetails)
            ? 'Lifetime'
            : isActive
              ? formatDaysRemaining(billingDetails, t('days_remaining'))
              : billingDetails && billingDetails.status
                ? formatPaymentStatus(billingDetails.status, t)
                : 'Loading...';

    // const coupon =
    //     billingDetails && billingDetails.couponPercentOff
    //         ? `${billingDetails.couponPercentOff}% OFF`
    //         : '';

    // If the user has semester plan, certain upgrade pop-ups will be shown
    // const isShowSemesterPlan =
    //     billingDetails &&
    //     (!billingDetails?.planId ||
    //         billingDetails?.planId !== SEMESTER_PLAN_PRICE_ID);

    useEffect(() => {
        if (!user) return;
        getDetails();
    }, [user]);

    const getDetails = async () => {
        const customerId = user?.data?.attributes['custom:stripeCustomerID'];

        setCustomerId(customerId);

        const response = await getStripeUserDetails(token, customerId, t);
        if (response) {
            const {
                planType,
                planEnd,
                planId,
                daysLeft,
                billingEmail,
                updatePaymentUrl,
                defaultPaymentMethod,
                metadata,
                balance,
                couponPercentOff,
                planAmountToShow,
                planNickName,
            } = response;

            setBillingDetails({
                status: planStatus,
                planType,
                planEnd,
                planId,
                daysLeft,
                billingEmail,
                updatePaymentUrl,
                defaultPaymentMethod,
                metadata,
                balance,
                couponPercentOff,
                planAmountToShow,
                planNickName,
            });

            if (defaultPaymentMethod) {
                const res = await loadCardDetails(token, defaultPaymentMethod);
                setDefaultPaymentDetails({
                    brand: res?.brand,
                    cardEnding: res?.last4,
                    expiryMonth: res?.exp_month,
                    expiryYear: res?.exp_year,
                });
            }
        }
    };

    /* const onCancelClick = () => {
        setIsShowSummerPopup(true);
    };

    const onCancelPopupCloseHandler = () => {
        setIsShowSummerPopup(false);
    };

    const onCancelPopupPassHandler = () => {
        setIsShowSummerPopup(false);
        handleCancelPlan();
    }; */

    /* const handleNewCancelPlan = async () => {
        setSummerPauseIsLoading(true);
        infoSnackbar('Pausing your subscription...', 2000);
        const customerId = user?.data?.attributes['custom:stripeCustomerID'];
        console.log('customerId >>>', customerId);
        const res = await newCancellation(token, customerId);
        logger.debug('handleNewCancelPlan >>>', res);
        if (res && res === 'success') {
            successSnackbar(
                'Your subscription has been paused until September 1st. We will miss you!',
            );
            setSummerPauseIsLoading(false);
            setIsShowSummerPopup(false);
        } else {
            setSummerPauseIsLoading(false);
            errorSnackbar(
                'Something went wrong while trying to pause your subscription. please try again later.',
            );
        }
    }; */

    const handleCancelPlan = async () => {
        const customerId = user?.data?.attributes['custom:stripeCustomerID'];
        const email = user?.data?.attributes.email;

        getChurnkeyHash(token, customerId)
            .then((val) => {
                logger.debug('Received hash val: ', val);
                (window as any).churnkey.init('show', {
                    customerId: customerId,
                    authHash: val,
                    appId: CK_ID,
                    mode: getChurnkeyEnvironment(),
                    provider: 'stripe',
                    onClose: () => {
                        // Reload after 1 second to let all API calls complete
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    },
                    handleSupportRequest: () => {
                        infoSnackbar(
                            'Please email support@studybuddy.gg. I copied the email to your clipboard!',
                        );
                        navigator.clipboard.writeText('support@studybuddy.gg');
                        (window as any).churnkey.hide();
                    },
                    onCancel: () => {
                        submitCancellationEvents(email);
                    },
                    onError: () => {
                        errorSnackbar(
                            'Something went wrong while trying to cancel. Please email support@studybuddy.gg and let them know you received this error while trying to cancel.',
                        );
                    },
                });
            })
            .catch((e) => {
                errorSnackbar(
                    'Something went wrong while trying to cancel. Please email support@studybuddy.gg and let them know you received this error while trying to cancel.',
                    10000,
                );
                logger.error(e);
            });
    };

    const onManagePaymentClick = () => {
        if (billingDetails?.updatePaymentUrl) {
            window.open(billingDetails.updatePaymentUrl, '_self');
        }
    };
    const onCancelTutorialPopupCloseHandler = () => {
        setIsShowTutorialPopUp(false);
    };
    // if (billingDetails == null) return <Loader />;

    return (
        <div className="dashboard-userInfo">
            <WatchTutorialPopup
                open={isShowTutorialPopUp}
                onClose={onCancelTutorialPopupCloseHandler}
            />
            {/* semester plan component */}
            {billingDetails == null ? (
                <Skeleton width={!isMobile ? '540px' : '300px'} count={5} />
            ) : (
                <div className="use-plan-container">
                    {/* Upgrade Semester */}
                    {isUserHaveActiveSubscription(billingDetails) ? (
                        <UpgradeSemester
                            token={token}
                            customerId={customerId}
                        />
                    ) : null}
                    <div className="semester-plan-container">
                        <div className="semester-plan-details">
                            {/* plan and price  */}
                            <div className="plan-price-container">
                                <div className="plan-info">
                                    <div className="card-text">
                                        <div className="card-image-container">
                                            <img
                                                src={getDashboardCardImage(
                                                    defaultPaymentDetails?.brand,
                                                )}
                                                alt="card"
                                            />
                                        </div>
                                        <p className="your-plan-text">
                                            {planName}
                                        </p>
                                    </div>
                                    {billingDetails?.planEnd && (
                                        <p className="plan-renew">
                                            {getRenewDate(
                                                Number(billingDetails?.planEnd),
                                            )}
                                        </p>
                                    )}
                                </div>
                                {planStatus?.startsWith('cancels') ? (
                                    <div className="expiry-text-body">
                                        <h5 className="expiry-text">{`Expired`}</h5>
                                    </div>
                                ) : Number.isNaN(
                                      billingDetails.planAmountToShow,
                                  ) ? null : (
                                    <h5 className="price-text">
                                        {`$${billingDetails?.planAmountToShow}`}
                                    </h5>
                                )}
                            </div>
                            {/* semester plan & renew  */}
                            {/* <div className="semester-plan-renew-container">
                                <div>
                                    <h5 className="semester-plan-title">
                                        {planName}
                                    </h5>
                                    <p className="billed-text">
                                        {billingDetails?.planType}
                                    </p>
                                </div>

                                <p className="renews-at-text">
                                    {planStatusMsg}
                                </p>
                            </div> */}
                        </div>

                        {/* manage payment button */}

                        {!isUserSubscribed(billingDetails) && isMobile ? (
                            <button className="resubscribe-button">
                                <ArrowUp />
                                Resubscribe{' '}
                            </button>
                        ) : (
                            <button
                                onClick={onManagePaymentClick}
                                className="manage-payment-button"
                            >
                                Manage Payment
                            </button>
                        )}

                        <div className="subs-btn-container">
                            <button
                                className="cancel-plan-btn"
                                onClick={handleCancelPlan}
                            >
                                Cancel Subscription
                            </button>
                            <button
                                className="cancel-plan-btn"
                                onClick={handleCancelPlan}
                            >
                                Pause Subscription
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DashboardUserInfo;

import { Assets } from '~constants';

export type OnboardingStepType = {
    id: number;
    stepName?: string;
    title: string;
    subTitle?: string;
    bulletPoints?: string[];
    subBulletPoints?: { title: string; bulletPoints: string[] }[];
    addtionalText?: string;
    assetPath?: string;
    assetType?: 'video' | 'image';
};

export const onboardingSteps: OnboardingStepType[] = [
    {
        id: 1,
        stepName: 'Add to Chrome',
        title: 'Step 1: Add our extension to your Chrome browser',
        assetPath: Assets.onboardingStep1,
        bulletPoints: [
            'Click blue button below to [[“Visit Chrome Store"]]',
            'On the new tab click [[“Download Extension”]]',
            'Once done [[come back to this page]] to finish onboarding',
        ],
        assetType: 'video',
    },
    {
        id: 2,
        stepName: 'Pin Extension',
        title: 'Step 2: Pin the extension and start using StudyBuddy',
        assetPath: Assets.onboardingStep2,
        assetType: 'video',
    },
    {
        id: 3,
        stepName: 'Share with a Friend',
        title: 'Step 3: Earn a [[Free Week]] for each friend that joins',
        assetPath: Assets.onboardingStep3,
        assetType: 'image',
    },
];

export const popupOnboardingArr = [
    {
        id: 1,
        title: 'Feedback Popup',
        subTitle: `Feedback popup`,
    },
    {
        id: 2,
        title: 'Video Popup',
        subTitle: `Video popup`,
    },
    {
        id: 3,
        title: 'Submit Form',
        subTitle: `Submit Form`,
    },
];

import { API } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useAuth, useScreenSize } from '~hooks';
import { useExtensionHelper } from '~utils/extension';
import DashboardKnowledges from '~components/Dashboard/DashboardKnowledges/DashboardKnowledges';
import { HowToUse } from '~components/Dashboard/HowToUse/HowToUse';
import { Onboarding } from '~components/Onboarding';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DashboardUserInfo from '~components/Dashboard/DashboardUserInfo/DashboardUserInfo';
import { Banner } from '~components/Banner';
import { DashboardFooter } from '~components/Dashboard/DashboardFooter';
import { MobileOnboard } from '~components/mobileOnboard'; // Import MobileOnboard
import { DashboardTools } from '~components/Dashboard/DashboardTools';
import './DashboardOverview.styles.scss';
import { API_NAME, STUDYBUDDY_AI_CHAT_API } from '~constants';
import { SemesterDealPopup } from '~components/Modals/SemesterDealPopup';
import {
    BillingDetails,
    getStripeUserDetails,
    loadCardDetails,
} from '~components/PaymentPlan';
import {
    isUserHaveActiveSubscription,
    isUserSubscribed,
} from '../Dashboard.function';
import { Footer } from '~pages/Homepage/Footer';

export const DashboardOverview = ({
    onboardingStatus,
}: {
    onboardingStatus: boolean;
}) => {
    const { isMobile } = useScreenSize();
    const { isExtensionInstalled } = useExtensionHelper();
    const [showOnboarding, setShowOnboarding] = useState(false);
    const [isSemesterDealPopupShow, setIsSemesterDealPopupShow] =
        useState(true);
    const [customerId, setCustomerId] = useState<string>('');
    const [billingDetails, setBillingDetails] = useState<BillingDetails | null>(
        null,
    );
    const { token, user, planStatus } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (!user) return;
        getDetails();
    }, [user]);

    const getDetails = async () => {
        const customerId = user?.data?.attributes['custom:stripeCustomerID'];

        setCustomerId(customerId);

        const response = await getStripeUserDetails(token, customerId, t);
        if (response) {
            const {
                planType,
                planEnd,
                planId,
                daysLeft,
                billingEmail,
                updatePaymentUrl,
                defaultPaymentMethod,
                metadata,
                balance,
                couponPercentOff,
                planAmountToShow,
                planNickName,
            } = response;

            setBillingDetails({
                status: planStatus,
                planType,
                planEnd,
                planId,
                daysLeft,
                billingEmail,
                updatePaymentUrl,
                defaultPaymentMethod,
                metadata,
                balance,
                couponPercentOff,
                planAmountToShow,
                planNickName,
            });
        }
    };
    const onHelpButtonClick = () => {
        navigate('/dashboard/contact');
    };

    const onInstallExtensionClickHandler = () => {
        setShowOnboarding(true);
    };

    // const onTestFunction = () => {
    //     console.log('test function clicked >>>');
    //     testFuncApi(token);
    // };

    const onSemesterDealPopupCloseHandler = () => {
        setIsSemesterDealPopupShow(false);
    };

    // useEffect(() => {
    //     const isOnboardingCompleted = localStorage.getItem(
    //         'isNewOnboardingCompleted',
    //     );
    //     if (!isOnboardingCompleted && !isExtensionInstalled) {
    //         setShowOnboarding(true);
    //     } else {
    //         setShowOnboarding(false);
    //     }
    // }, [isExtensionInstalled]);

    useEffect(() => {
        if (onboardingStatus) {
            setShowOnboarding(true);
        } else {
            setShowOnboarding(false);
        }
    }, [onboardingStatus]);

    // useEffect(() => {
    //     (window as any).botpressWebChat.mergeConfig({ hideWidget: false });
    // }, []);

    // useEffect(() => {
    //     const randomNumber = Math.floor(Math.random() * 10) + 1;
    //     console.log('number', randomNumber);
    //     console.log(isSemesterDealPopupShow);
    //     setIsSemesterDealPopupShow(randomNumber >= 3 && randomNumber <= 6);
    // }, []);
    // const isUserNotSubscribedSemester = isUserSubscribed(billingDetails);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        if (isUserSubscribed(billingDetails)) {
            timer = setTimeout(() => {
                setIsSemesterDealPopupShow(true);
            }, 20000); // Delay by 20 seconds
        } else {
            setIsSemesterDealPopupShow(false);
        }

        return () => clearTimeout(timer);
    }, [billingDetails]);

    if (isMobile && showOnboarding) {
        return <MobileOnboard />;
    }

    if (showOnboarding) {
        return (
            <Onboarding showOnboarding setShowOnboarding={setShowOnboarding} />
        );
    }

    // const testFuncApi = async (token: string | undefined) => {
    //     if (!token) {
    //         return;
    //     }
    //     try {
    //         const response = await API.post(API_NAME, STUDYBUDDY_AI_CHAT_API, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //                 'Content-Type': 'application/json',
    //             },
    //             body: {
    //                 data: [
    //                     {
    //                         role: 'user',
    //                         content: 'Hi',
    //                     },
    //                     {
    //                         role: 'assistant',
    //                         content: 'Hi',
    //                     },
    //                     {
    //                         role: 'user',
    //                         content: 'What is nodejs?',
    //                     },
    //                     {
    //                         role: 'assistant',
    //                         content:
    //                             "Node.js is a JavaScript runtime built on Chrome's V8 JavaScript engine.",
    //                     },
    //                     {
    //                         role: 'user',
    //                         content: 'Who invented this?',
    //                     },
    //                 ],
    //             },
    //         });

    //         console.log('responmse --------->', response);
    //     } catch (err: any) {
    //         console.log('error -------------->', err);

    //         console.error(err);
    //     }
    // };

    return (
        <>
            <Container style={{ maxWidth: '1140px' }}>
                {isUserHaveActiveSubscription(billingDetails) ? (
                    <SemesterDealPopup
                        show={isSemesterDealPopupShow}
                        handleClose={onSemesterDealPopupCloseHandler}
                    />
                ) : null}
                <div className="dashboard-overview">
                    <div className="dashboard-overview__content">
                        <Banner />
                        {/* <button onClick={() => testFuncApi(token)}>Test button</button> */}
                        <DashboardTools />
                        <div className="dashboard-body">
                            <DashboardUserInfo
                                showOnboarding={showOnboarding}
                                setShowOnboarding={setShowOnboarding}
                            />
                            <DashboardKnowledges
                                showOnboarding={showOnboarding}
                                setShowOnboarding={setShowOnboarding}
                            />
                        </div>
                    </div>
                </div>
                <HowToUse videoMaxSize="100%" />
            </Container>
            <Footer />
        </>
    );
};

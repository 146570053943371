import React, { useState } from 'react';
import ToolItem from './ToolItem/ToolItem';
import pencilCircledIcon from '~/assets/pencil-circled.svg';
import ninjaCicledIcon from '~/assets/ninja-circled.svg';
import scanCircledIcon from '~/assets/scan-circled.svg';
import plusIcon from '~/assets/plus.svg';
import { WriterSteps } from '../WriterSteps';

import './WriterTools.styles.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '~constants';

const WriterTools = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()

    const handleRedirectAITextHumanizer = () => {
        navigate(AppRoutes.WriterAITextHumanizer);
    };
    const handleRedirectAIDetector = () => {
        navigate(AppRoutes.WriterAIDetector);
    };
    const handleRedirectContentGenerator = () => {
        navigate(AppRoutes.WriterContentgenerator);
    };
    return (
        <>
            <div className="tools-container">
                <p className="tools-text">{t('writer.tools')}</p>
                <div className="dashboard-tool-items">
                    <ToolItem
                        icon={pencilCircledIcon}
                        title={t('writer.essay_writer')}
                        buttonText={t('writer.start_writing')}
                        action={handleRedirectContentGenerator}
                        // buttonIcon={plusIcon}
                    >
                        {t('writer.write')}{' '}
                        <strong> {t('writer.a+essays')}</strong>{' '}
                        {t('writer.tool_item1')}
                    </ToolItem>
                    <ToolItem
                        icon={ninjaCicledIcon}
                        title={t('writer.humanize_ai_text')}
                        buttonText={t('writer.upload_your_text')}
                        action={handleRedirectAITextHumanizer}
                        buttonIcon={plusIcon}
                    >
                        <strong>{t('writer.ai_humanizer')}</strong>{' '}
                        {t('writer.tool_item2')}
                    </ToolItem>
                    <ToolItem
                        icon={scanCircledIcon}
                        title={t('writer.ai_detector')}
                        buttonText={t('writer.check_your_text')}
                        action={handleRedirectAIDetector}
                        // buttonIcon={plusIcon}
                    >
                        {t('writer.tool_item3')}{' '}
                        <strong> {t('writer.ai_detection_platform')} </strong>
                    </ToolItem>
                </div>
            </div>
        </>
    );
};

export default WriterTools;

import { useTranslation } from 'react-i18next';
import WriterTools from '../WriterTools/WriterTools';
import DocumentCard from './DocumentCard/DocumentCard';
import './WriterDocument.styles.scss';
export const WriterDocument = () => {
    const { t } = useTranslation();

    return (
        <>
            <header>
                <h1 className="dashboard-title d-flex">
                    {t('writer.heading')}
                </h1>
                <p className="document-sub-title">
                    {t('writer.in_depth_view_desc')}
                </p>
            </header>
            <div className="document-container">
                <WriterTools />
                <h2 className="documents-title">{t('writer.documents')}</h2>
                <div className="documents-cards-container">
                    {Array(8)
                        .fill(0)
                        .map((_, index) => {
                            return <DocumentCard key={index} />;
                        })}
                </div>
            </div>
        </>
    );
};

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useScreenSize } from '~hooks';
import { AppRoutes, icons } from '~constants';
import { writerSteps } from './WriterSteps.constants';
import './WriterSteps.styles.scss';
import { Steps } from './Steps';
import { useTranslation } from 'react-i18next';
import { extractTitles } from '~utils';

type DataParamsType = {
    topic: string;
    length: number;
    themePick: string;
    title_list:string[];
};

export type WriterStepsProps = {
    show: boolean;
    handleClose: () => void;
    handleCTA: () => void;
    onGenerageContent: (dataParams: DataParamsType) => void;
};

export const WriterSteps: React.FC<WriterStepsProps> = (props) => {
    const [step, setStep] = useState(1);
    const [topic, setTopic] = useState<string | undefined>();
    const [long, setLong] = useState(1);
    const [tone, setTone] = useState<string | undefined>();
    const [title, setTitle] = useState<string | null>(null); // State to store the title

    const { isMobile } = useScreenSize();
    const { show, handleClose, onGenerageContent } = props;

    const navigate = useNavigate();

    const { t } = useTranslation();

    const handleLeftButtonClick = () => {
        if (step === 1 && !isMobile) {
            navigate(-1);
            return;
        }
        setStep(step - 1);
    };

    const handleRightButtonClick = async () => {
        if(!topic){
            return
        }
        if (step < 4) {
            setStep(step + 1);
        } else {
            // Prepare data to pass
            const dataParams = {
                topic,
                length: long * 300,
                themePick: tone,
                title_list:extractTitles(title as string)
            } as DataParamsType;

            // Navigate with data (e.g., as state)
            console.log(dataParams);
            // navigate(AppRoutes.WriterContentgenerator, {
            //     state: { dataParams },
            // });

            // Optionally, you can log or handle the data here
            onGenerageContent(dataParams);
            // console.log('Navigating with data', dataParams);
        }
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            contentClassName="modalContent"
        >
            <div className="writer-steps-section">
                <h1 className="step-title d-flex align-items-center justify-content-center">
                    {writerSteps[step - 1].icon && (
                        <img src={writerSteps[step - 1].icon} />
                    )}
                    {writerSteps[step - 1].stepName}
                </h1>
                <div className="steps-tab">
                    {writerSteps.map((writerStep) => (
                        <div className="step-container" key={writerStep.id}>
                            <div
                                className={`step-circle ${writerStep.id <= step ? 'completed' : ''} ${writerStep.id === step ? 'active' : ''}`}
                            >
                                <p>{writerStep.id}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <Steps
                    steps={step}
                    topic={topic}
                    long={long}
                    tone={tone}
                    title={title}
                    setTone={setTone}
                    setLong={setLong}
                    setTopic={setTopic}
                    setTitle={setTitle}
                />

                <div className="action-buttons">
                    <div
                        className="cta-btn transparent"
                        onClick={handleLeftButtonClick}
                    >
                        <img src={icons.leftAngleArrow} alt="left" />
                        {t('Back')}
                    </div>
                    {
                        step ===4 ?
                        <div className="cta-btn cta-btn--gradient" onClick={handleRightButtonClick}>
                        <img src={icons.pencilIcon} alt="right" />
                        {t('writer.generate_essay_cta')}
                        
                    </div>
                         :
                    <div className={`cta-btn ${!topic ? 'cta-btn--disabled': ''}` } onClick={handleRightButtonClick} >
                        {t('next')}
                        <img src={icons.rightAngleArrowWhite} alt="right" />
                    </div>
                    }
                </div>
            </div>
        </Modal>
    );
};

import {
    PARAPHRASE_CONTENT_URL,
    PARAPHRASE_CONTENT_RETRIVE_URL,
    AI_DETECTOR_URL,
} from '~constants/urls';
import { postWithAuth } from '~utils/apiUtil';
import { delay } from '~utils';
import { AiDetectionResponse } from '../AiDetector/WriterAIDetector';
import { defaultErrorMessage } from '~constants';

export const MIN_WORDS_FOR_AI_CHECK = 50;
export const MIN_WORDS_FOR_HUMANIZE = 20;

export const getWordCount = (text: string): number => {
    if(!text){
        return 0;
    }
    return text.trim().split(/\s+/).length;
};

export const fetchHumanizedText = async (
    text: string,
    token: string,
): Promise<string> => {
    const paraphraseResponse = await postWithAuth(
        PARAPHRASE_CONTENT_URL,
        { generatedText: text },
        token,
    );

    let response = null;
    let tryCount = 1;
    const retrieveContent = async (): Promise<string> => {
        await delay(3000);
        tryCount++;
        const retrieveId = paraphraseResponse.data?.msg?.id;
        response = await postWithAuth(
            PARAPHRASE_CONTENT_RETRIVE_URL,
            { id: retrieveId },
            token,
        );
        if (response.data?.data?.output === null) {
            if(tryCount < 3){
                return retrieveContent();
            }
            else{
                throw new Error(defaultErrorMessage)
            }
        }
        return response.data?.data?.output || '';
    };

    return retrieveContent();
};

export const fetchAIDetection = async (
    text: string,
    token: string,
): Promise<AiDetectionResponse> => {
    const sanitizedText = text.replaceAll('\n', ' ');
    try{
        const response = await postWithAuth(
            AI_DETECTOR_URL,
            { textContent: sanitizedText },
            token,
        );
        return response.data;
    }
    catch(err){
        throw err;
    }
};

import type { FC } from 'react';
import React from 'react';

import type { SubmitEmailProps } from './SubmitEmail.types';
import { inputFieldStyles } from '../ForgetPassword.constants';
import { InputField } from '~components/InputField';
import { Loader } from '~components/Loader';

import './SubmitEmail.styles.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '~constants';

export const SubmitEmail: FC<SubmitEmailProps> = (props) => {
    const { onChange, formData, formErrors, onForgetPassword, isLoading } =
        props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div
            className={`submit-email-section ${formErrors?.email ? 'red-border' : 'grey-border'} `}
        >
            <InputField
                name="email"
                type="email"
                required
                label={t('email')}
                value={formData.email}
                onChange={onChange}
                placeholder={t('enter_email')}
                style={inputFieldStyles}
                error={formErrors?.email}
            />
            <button onClick={onForgetPassword} className="forgot-cta-btn">
                {isLoading ? (
                    <Loader color="#fff" />
                ) : (
                    <span>{t('send_code')}</span>
                )}
            </button>
            <button
                onClick={() => navigate(AppRoutes.Login)}
                className="forgot-cta-btn-outlined"
            >
                {'Back'}
            </button>
        </div>
    );
};

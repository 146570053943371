import React from 'react';
import Form from 'react-bootstrap/Form';
import './Slider.scss';

interface SliderProps {
    items: { label: string; value: number,subHead:string }[];
    selectedIndex: number;
    setSelectedIndex: (index: number) => void;
}

const Slider: React.FC<SliderProps> = ({ items, selectedIndex, setSelectedIndex }) => {
    const handelRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedIndex(parseInt(e.target.value));
    };

    return (
        <div className="slider">
            <div className='range-container'>
                <Form.Range
                    min={0}
                    max={items.length - 1}
                    step={1}
                    value={selectedIndex}
                    onChange={handelRangeChange}
                />
                <span className='range-indicator' style={{left:`${selectedIndex*33}%`}}></span>
            </div>
            
            <div className="ranges-container">
                {items.map((item, index) => (
                    <div key={index} className="range-item">
                        <p>{item.label}</p>
                        <p>{`${item.subHead}`}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Slider;

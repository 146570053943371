export const defaultErrorMessage =
    'Something went wrong loading the payment. Please refresh or contact support.';

export const paymentElementOptions: any = {
    // layout: 'tabs',
    layout: {
        type: 'accordion',
        defaultCollapsed: false,
        radios: false,
        spacedAccordionItems: true,
    },
    terms: {
        applePay: 'never',
        card: 'never',
        googlePay: 'never',
        ideal: 'never',
        sofort: 'never',
    },
    paymentMethodOrder: ['card', 'apple_pay', 'google_pay', ]
};

import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';

export type ExportFormat = 'PDF' | 'DOCX';

export function textToPdf(text: string, filename = 'document.pdf') {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 10;
    const maxWidth = pageWidth - 2 * margin;
    const lineHeight = 7;

    doc.setFont('helvetica').setFontSize(12);

    const lines = doc.splitTextToSize(text, maxWidth);
    console.log(lines, 'lineslineslineslineslines');
    let cursorY = margin;

    lines.forEach((line: string, index: number) => {
        if (cursorY > pageHeight - margin) {
            doc.addPage();
            cursorY = margin;
        }

        doc.text(line, margin, cursorY);
        cursorY += lineHeight;

        // Add extra space after paragraphs
        if (
            index < lines.length - 1 &&
            line.trim() === '' &&
            lines[index + 1].trim() !== ''
        ) {
            cursorY += lineHeight;
        }
    });
    const pdfBlob = doc.output('blob');

    saveAs(pdfBlob, filename);
}

export function saveAsDocx(text: string) {
    const blob = new Blob([text], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
    saveAs(blob, 'document.docx');
}

export function exportBasedOnType(text: string, type: ExportFormat = 'PDF') {
    if (!text) {
        return null;
    }

    if (type === 'PDF') {
        textToPdf(text);
    } else {
        saveAsDocx(text);
    }
}

import { getClassNameByNumber } from '~utils/getClassByNumber';
import './AIlikeliHood.styles.scss';
import { useTranslation } from 'react-i18next';

interface AiLikeLiHoodPercentageProps {
    num?: number;
}
const AiLikeLiHoodPercentage: React.FC<AiLikeLiHoodPercentageProps> = ({
    num,
}) => {
    const { t } = useTranslation();

    const percentage = typeof num === t('number') ? `${num}%` : '%';

    return (
        <>
            <div className={`ai-generated-text ${getClassNameByNumber(num)}`}>
                {percentage}
            </div>
            <div> {t('writer.ai_likely_text_generate')} </div>
        </>
    );
};

export default AiLikeLiHoodPercentage;

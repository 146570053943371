import { Navigate, Outlet, useMatch } from 'react-router-dom';
import { DashboardOverview } from './DashboardOverview';
import React, { useEffect, useMemo, useState } from 'react';

import { AppRoutes, CK_ID } from '~constants';
import { useAuth, useScreenSize, useSnackbar } from '~hooks';
import { getChurnkeyEnvironment, logger } from '~utils';
import {
    emitPlaceOrderEvent,
    emitReferralEvent,
} from '~utils/advertisingEvents';
import { DashboardNavPanel } from '~components/Dashboard/DashboardNavPanel';
import { Banner } from '~components/Banner';
import { getChurnkeyHash } from '~components/CancellationFlow/CancellationFlow.functions';
import Confetti from '~components/Utility/Confetti';
import { SharePopup } from '~components/Modals/SharePopup';
import { SemesterPlanPopup } from '~components/Modals/SemesterPlanPopup';

import { ReferralPopup } from '~components/ReferralPopup/ReferralPopup';
import { useTranslation } from 'react-i18next';
// import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { DisturbancePopup } from '~components/Modals/DisturbancePopup';
import { DashboardHeader } from '~components/Dashboard/WelcomeHeader';
import { FancyLoader } from '~components/Loader';

import './Dashboard.styles.scss';
import { useExtensionHelper } from '~utils/extension';

export const Dashboard = () => {
    const match = useMatch('/dashboard');
    const {
        token,
        user,
        logout,
        updateAuthState,
        isAuthenticated,
        planStatus,
    } = useAuth();
    const [isViralLoopVisible, setIsViralLoopVisible] = useState(false);
    // const [showConfetti, setShowConfetti] = useState(false);
    const [isSemesterModalShow, setIsSemesterModalShow] = useState(false);
    const [isReferralModalShow, setIsReferralModalShow] = useState(false);
    const [isCheckoutCompleted, setIsCheckoutCompleted] = useState(false);
    const [isDisturbancePopupShow, setIsDisturbancePopupShow] = useState(false);

    const [onboardingStatus, setOnboardingStatus] = useState(false);
    const [loading, setLoading] = useState(true);
    const { infoSnackbar } = useSnackbar();
    const { isMobile } = useScreenSize();
    const { t } = useTranslation();
    const { isExtensionInstalled } = useExtensionHelper();
    // const isNewWeeklyPricingOn = useFeatureIsOn('weekly-pricing-test');

    // Check if this is a successful payment and emit the necessary events

    const checkAuth = async () => {
        setLoading(true);
        await updateAuthState();
        const payment_status = sessionStorage.getItem('payment_status');

        if (payment_status === 'success') {
            emitPlaceOrderEvent();
            emitReferralEvent(
                token,
                user?.data?.attributes['custom:stripeCustomerID'],
            );
            setIsCheckoutCompleted(true);
            sessionStorage.removeItem('payment_status');
        }
        setLoading(false);
    };
    useEffect(() => {
        checkAuth();
    }, []);

    useEffect(() => {
        if (
            user &&
            user.data &&
            user.data?.attributes['custom:stripeCustomerID']
        ) {
            const customerId = user.data.attributes['custom:stripeCustomerID'];
            getChurnkeyHash(token, customerId)
                .then((val) => {
                    // Pause wall
                    /* (window as any).churnkey.check('pause', {
                    customerId: customerId,
                    authHash: val,
                    appId: CK_ID,
                    mode: getChurnkeyEnvironment(),
                    provider: 'stripe',
                    softWall: false,
                    forceCheck: false,
                    handleLogout() {
                        logout();
                        window.location.reload();
                    },
                    onPauseWallClose() {
                        window.location.reload();
                    },
                }); */

                    // Failed payment wall
                    (window as any).churnkey.check('failed-payment', {
                        customerId: customerId,
                        authHash: val,
                        appId: CK_ID,
                        mode: getChurnkeyEnvironment(),
                        provider: 'stripe',
                        softWall: false,
                        forceCheck: false,
                        gracePeriodDays: 7,
                        ignoreInvoicesWithoutAttempt: true,
                        handleLogout() {
                            logout();
                            window.location.reload();
                        },
                        handleSupportRequest() {
                            infoSnackbar(
                                'Please email support@studybuddy.gg. I copied it to your clipboard!',
                            );
                            navigator.clipboard.writeText(
                                'support@studybuddy.gg',
                            );
                        },
                        onFailedPaymentWallClose() {
                            window.location.reload();
                        },
                    });
                })
                .catch((e: any) => {
                    logger.error(e);
                });
        }
    }, [user]);

    const onSemesterModalCloseHandler = () => {
        setIsSemesterModalShow(false);
    };

    /* const onDisturbancePopupCloseHandler = () => {
        setIsDisturbancePopupShow(false);
    }; */

    /* const onDisturbancePopupCtaHandler = () => {
        setIsDisturbancePopupShow(false);
        setOnboardingStatus(true);
    }; */

    /* useEffect(() => {
        if (!isMobile) {
            const randomIndex: number = Math.floor(Math.random() * 2);

            if (randomIndex !== 0) {
                setIsReferralModalShow(false);
            } else {
                setIsSemesterModalShow(false);
            }
            const isOnboardingCompleted = localStorage.getItem(
                'isNewOnboardingCompleted',
            );

            if (!isOnboardingCompleted) {
                setIsDisturbancePopupShow(true);
            }
        }
    }, [isMobile]); */

    console.log('isLoading ___', loading, planStatus);
    useEffect(() => {
        const isOnboardingCompleted = localStorage.getItem(
            'isNewOnboardingCompleted',
        );
        if (!isOnboardingCompleted && !isExtensionInstalled) {
            setOnboardingStatus(true);
        } else {
            setOnboardingStatus(false);
        }
    }, [isExtensionInstalled]);

    if (loading) {
        return <FancyLoader />;
    }

    if (!loading && isAuthenticated && !planStatus && !isCheckoutCompleted) {
        logger.debug('not having any plan ___', isAuthenticated);
        return <Navigate to={AppRoutes.Signin_Redirect} />;
    }

    if (!loading && !isAuthenticated) {
        logger.debug('isAuthenticated >>>:', isAuthenticated);
        return <Navigate to={AppRoutes.Login} />;
    }

    return (
        <div className="dashboard-page">
            {!onboardingStatus && <DashboardHeader />}
            {/* {!isMobile && <DashboardHeader />} */}

            <SemesterPlanPopup
                open={isSemesterModalShow}
                onClose={onSemesterModalCloseHandler}
            />
            <ReferralPopup
                open={isReferralModalShow}
                setOpen={setIsReferralModalShow}
            />
            {/* {isDisturbancePopupShow && !isMobile && (
                <DisturbancePopup
                    show={isDisturbancePopupShow}
                    handleClose={onDisturbancePopupCloseHandler}
                    handleCta={onDisturbancePopupCtaHandler}
                    isDashboard
                />
            )} */}
            {isViralLoopVisible ? (
                <SharePopup
                    isShowLogo={isMobile}
                    handleIsSharePopup={setIsViralLoopVisible}
                />
            ) : (
                <>
                    <div className="dashboard-page__hero">
                        {/* <DashboardNavPanel /> */}
                        <div className="hero-content">
                            {/* {showConfetti && !isViralLoopVisible && (
                                <Confetti />
                            )} */}
                            {/* <Banner /> */}
                            {match ? (
                                <DashboardOverview
                                    onboardingStatus={onboardingStatus}
                                />
                            ) : (
                                <Outlet />
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

import React, { FC, useState } from 'react';
import { OnBoardingButtonProps } from '../OnboardingStep.types';
import { icons } from '~constants';
import { useScreenSize, useSnackbar } from '~hooks';
import SemesterDealUpsell from '~components/Onboarding/SemesterDealUpsell';
import TurboModeCrossSell from '~components/Onboarding/TurboModeCrossSell';
import { useTranslation } from 'react-i18next';
import XBlackLogo from '~/assets/XLogo_black.svg';
import InstaBlackLogo from '~/assets/InstaLogo_black.svg';

import './OnboardingButton.styles.scss';
export const OnboardingButton: FC<OnBoardingButtonProps> = (props) => {
    const {
        isShowSemesterUpsell,
        isShowTurboCrossSell,
        step,
        isShowExtenstionBtn,
        isExtensionInstalled,
        onLeftButtonClick,
        increaseStep,
        isShowDoneBtn,
        onRightButtonClick,
        isTurboModeActive,
        isSubscribedToSemester,
        referralUrl,
    } = props;

    const { isMobile, isTablet } = useScreenSize();
    const { successSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [isLinkCopied, setIsLinkCopied] = useState(false);
    const showShareButton = step === 3;
    return (
        <div className="button-container">
            {!isShowSemesterUpsell && !isShowTurboCrossSell && (
                <div
                    className={`onboarding-buttons ${step === 3 ? 'step_3' : ''}`}
                >
                    {isShowExtenstionBtn ? (
                        <>
                            <button
                                className={
                                    isExtensionInstalled
                                        ? 'cta-btn transparent'
                                        : 'cta-btn'
                                }
                                onClick={onLeftButtonClick}
                            >
                                <img
                                    src={
                                        isExtensionInstalled
                                            ? icons.checkBlue
                                            : icons.chromeWhite
                                    }
                                    alt="open"
                                />
                                {isExtensionInstalled
                                    ? 'Done'
                                    : 'Visit Chrome Store'}
                            </button>
                            <button
                                disabled={isExtensionInstalled ? false : true}
                                className={
                                    isExtensionInstalled
                                        ? 'cta-btn'
                                        : 'cta-btn-disabled'
                                }
                                onClick={increaseStep}
                            >
                                {isShowDoneBtn ? (
                                    <span>Done</span>
                                ) : (
                                    <span>
                                        Next Step
                                        <img
                                            src={
                                                isExtensionInstalled
                                                    ? icons.rightAngleArrowWhite
                                                    : icons.rightAngleArrowGrey
                                            }
                                            alt="icon"
                                        />
                                    </span>
                                )}
                            </button>
                        </>
                    ) : (
                        <>
                            {/* Show Back button if not mobile/tablet and done button is not shown */}
                            {!isMobile &&
                                !isTablet &&
                                !isShowDoneBtn &&
                                !showShareButton && (
                                    <button
                                        className="cta-btn transparent"
                                        onClick={onLeftButtonClick}
                                    >
                                        <span>Back</span>
                                    </button>
                                )}

                            {/* Copy referral link if show done */}
                            {showShareButton && (
                                <div className="copy-link-container">
                                    <button
                                        onClick={() => {
                                            if (!isLinkCopied) {
                                                navigator.clipboard
                                                    .writeText(referralUrl)
                                                    .then(() => {
                                                        setIsLinkCopied(true);
                                                        successSnackbar(
                                                            t(
                                                                'referral_success',
                                                            ),
                                                        );
                                                    });
                                            }
                                        }}
                                        className={`referral-copy-button ${isLinkCopied && 'transparent'} `}
                                    >
                                        <img
                                            src={
                                                isLinkCopied
                                                    ? icons.checkBlue
                                                    : icons.shareReferral
                                            }
                                            alt="share icon"
                                        />
                                        {isLinkCopied
                                            ? 'Link Copied'
                                            : 'Copy Link'}
                                    </button>
                                    {/* <div className="social-container">
                                        <img
                                            src={icons.FBBlack}
                                            alt="facebook"
                                        />
                                        <img
                                            src={icons.WhatsappBlack}
                                            alt="whatsapp"
                                        />
                                        <img
                                            src={InstaBlackLogo}
                                            alt="instagram"
                                        />
                                        <img src={XBlackLogo} alt="x" />
                                    </div> */}
                                </div>
                            )}

                            {/* Show Next button */}
                            <button
                                disabled={!isLinkCopied && showShareButton}
                                className="cta-btn"
                                onClick={increaseStep}
                            >
                                {showShareButton ? (
                                    <span>Continue</span>
                                ) : (
                                    <span>
                                        Next Step
                                        <img
                                            src={icons.rightAngleArrowWhite}
                                            alt="icon"
                                        />
                                    </span>
                                )}
                            </button>
                        </>
                    )}
                </div>
            )}

            {showShareButton && (
                <p className="not-interested-btn" onClick={increaseStep}>
                    No, I don’t want to help my friends
                </p>
            )}

            {isShowSemesterUpsell && (
                <SemesterDealUpsell
                    increaseStep={increaseStep}
                    isSubscribedToSemester={isSubscribedToSemester}
                    onRightButtonClick={onRightButtonClick}
                />
            )}

            {isShowTurboCrossSell && (
                <TurboModeCrossSell
                    isTurboModeActive={isTurboModeActive}
                    onRightButtonClick={onRightButtonClick}
                />
            )}
        </div>
    );
};

export default OnboardingButton;

import type { FC } from 'react';
import React from 'react';
import { Container, Form } from 'react-bootstrap';

import {
    AppRoutes,
    icons,
    PRIVACY_POLICY_URL,
    TERMS_OF_SERVICE_URL,
} from '~constants';
import { inputFieldStyles } from './LoginForm.constants';
import type { LoginFormProps } from './LoginForm.types';
import { InputField } from '~components/InputField';
import { Link } from 'react-router-dom';
import { Loader } from '~components/Loader';
import { SocialLogins } from '~components/SocialLogins';

import './LoginForm.styles.scss';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from '~hooks';

export const LoginForm: FC<LoginFormProps> = (props) => {
    const { formData, onChange, onSubmit, formErrors, isButtonLoading } = props;

    const { t } = useTranslation();
    const { isMobile } = useScreenSize();

    return (
        <Container className={'login-form'}>
            <div className={isMobile ? 'login-left-align' : 'login-desktop'}>
                <h1>{t('login_to')} Studybuddy</h1>
                <h3>
                    {t('dont_have_an_account')}{' '}
                    <Link to={AppRoutes.Register}>{t('create_one')}</Link>
                </h3>
            </div>
            <Form onSubmit={onSubmit} className="form grey-border">
                <InputField
                    type="email"
                    name="email"
                    label={t('email')}
                    value={formData.email}
                    onChange={onChange}
                    placeholder={t('enter_email')}
                    style={inputFieldStyles}
                    error={formErrors?.email}
                />

                <InputField
                    type="password"
                    name="password"
                    label={t('password')}
                    value={formData.password}
                    onChange={onChange}
                    placeholder={t('enter_password')}
                    style={inputFieldStyles}
                    error={formErrors?.password}
                />
                <p className="forget-pass">
                    {t('forgot_password')}{' '}
                    <Link to={AppRoutes.ForgetPassword}>
                        {t('Reset Password')}
                    </Link>
                </p>
                <button className="cta-btn" type="submit">
                    {isButtonLoading ? (
                        <Loader color="#fff" />
                    ) : (
                        <span>{t('Continue')}</span>
                    )}
                </button>
            </Form>
            <div className="divider">
                <span>{t('or')}</span>
            </div>
            <div className="social-logins-section">
                <SocialLogins />
            </div>
            <p className="terms">
                {t('by_logging_in')}
                <Link to={TERMS_OF_SERVICE_URL}>
                    {/* <br /> */}
                    {t('terms_and_conditions')}
                </Link>{' '}
                {t('and')}
                <Link to={PRIVACY_POLICY_URL}>{t('privacy_policy')}.</Link>
            </p>
        </Container>
    );
};

import { ReactElement } from 'react';
import './ToolCard.styles.scss';
type Props = {
    imgSrc: string;
    title: string;
    subTitle: string | ReactElement;
    desktopOnly: boolean;
    buttonText: string;
    btnIcon: React.ReactNode;
    cardAction: () => void;
    isDisabled:boolean;
};

const ToolCard = ({
    imgSrc,
    subTitle,
    title,
    desktopOnly,
    buttonText,
    btnIcon,
    cardAction,
    isDisabled
}: Props) => {
    return (
        <div className="tool-card-container">
            <div className="card-heading">
                <h1>{title}</h1>
                <p>{subTitle}</p>
            </div>
            <button onClick={cardAction} disabled={desktopOnly  || isDisabled}>
                {!desktopOnly && btnIcon}
                {buttonText}
            </button>
            <div className="image-container">
                <img src={imgSrc} alt="tool" />
            </div>
        </div>
    );
};

export default ToolCard;

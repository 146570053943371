import React, { useState } from 'react';
import './OrderSummary.styles.scss';
import { useScreenSize } from '~hooks';
import { Assets } from '~constants';

const OrderSummary = () => {
    const [showDetails, setShowDetails] = useState(false);
    const { isMobile } = useScreenSize();
    return (
        <div className="order-summary-container">
            <h3 className="review-order-title">
                {isMobile ? 'Review your order summary' : 'Review your order'}
            </h3>
            <div className="flex-between">
                <h4 className="category-header">Item</h4>
                <h4 className="category-header">Price</h4>
            </div>
            <div className="flex-between">
                <div>
                    <p className="price-body">
                        {isMobile ? 'StudyBuddy 3-Day Trial' : '3-Day Trial'}
                    </p>
                    <div onClick={() => setShowDetails(!showDetails)}>
                        <p className="price-body details-text">
                            {showDetails ? 'Hide Details' : 'See Details'}
                        </p>
                    </div>
                </div>
                <p className="price-body">
                    <strong>$0.99</strong>
                </p>
            </div>

            {showDetails && (
                <div className="flex-between">
                    <img
                        style={{ objectFit: 'contain', width: '300px' }}
                        src={Assets.checkoutDetails}
                        alt="studybuddy-features"
                    />
                </div>
            )}

            <div className="flex-between">
                <h4 className="category-header checkout-text">Total Due</h4>
                <h4 className="category-header checkout-text">$0.99</h4>
            </div>
        </div>
    );
};

export default OrderSummary;

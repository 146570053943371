
import { useAuth, useScreenSize, useSnackbar } from '~hooks';
import { useEffect, useState } from 'react';
import { logger } from '~utils';

import { VIRAL_SHARE_TEXT } from '~components/Modals/SharePopup/SharePopup.constants';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CopyIcon } from '~assets/dashboard-copy.svg';

import './Referral.styles.scss';

export const Referral = () => {
    const { user } = useAuth();
    const [referralUrl, setReferralUrl] = useState<string>('');
    const { successSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const { isMobile } = useScreenSize();

    useEffect(() => {
        let code = user?.data?.attributes['custom:stripeCustomerID'];
        if (!code || !code.startsWith('cus_')) {
            logger.error(
                'No Stripe Customer ID found when trying to create referral code' as any,
            );
        } else {
            code = code.replace('cus_', '');
            setReferralUrl(`https://studybuddy.gg?ref=${code}`);
        }
    }, [user]);

    const copyToClipboard = (event: any) => {
        event.preventDefault();
        navigator.clipboard.writeText(VIRAL_SHARE_TEXT + ' ' + referralUrl);
        successSnackbar(t('referral_success'));
    };

    return (
        <div className="refer">
            <h2>
                {t('earn_a')} <span> {t('free_week')?.slice(0, -1)} </span>
                {t('for_each')}<br/>{t('you_refer')}
            </h2>

            <div className="referral-info-container">
                <div className="referral-link-container">
                    <div className="referral-link">{referralUrl}</div>
                    <button onClick={copyToClipboard}>
                        {' '}
                        <CopyIcon /> {!isMobile && 'Copy Link'}
                    </button>
                </div>
                {/* <div className="referral-reward-count-container">
                    <div className="total-referral">
                        <h2>
                            {' '}
                            <UserIcon /> Total Referrals{' '}
                        </h2>
                        <p> 6 </p>
                    </div>
                    <div className="white-line"></div>
                    <div className="total-earned">
                        <h2>
                            {' '}
                            <GiftIcon /> {!isMobile && 'Total'} Weeks Earned{' '}
                        </h2>
                        <p> 6 </p>
                    </div>
                </div> */}
            </div>
            {/* </InputGroup> */}

            {/* <Col className="share-icons" md={12} lg={5}>
                <Stack direction="horizontal" gap={3}>
                    <b>Share </b>
                    <a
                        href={`sms:?&body=${encodeURIComponent(VIRAL_SHARE_TEXT + ' ' + referralUrl)}`}
                    >
                        <img src={Assets.iMessageLogo} />
                    </a>
                    <a
                        href={`https://wa.me/?text=${encodeURIComponent(VIRAL_SHARE_TEXT + ' ' + referralUrl)}`}
                        target="_blank"
                    >
                        <img src={Assets.whatsAppLogo} />
                    </a>
                    <a
                        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(VIRAL_SHARE_TEXT + ' ' + referralUrl)}`}
                        target="_blank"
                    >
                        <img src={Assets.twitterLogo} />
                    </a>
                    <a
                        href={`https://telegram.me/share/url?url=${encodeURIComponent(referralUrl)}&text=${VIRAL_SHARE_TEXT}`}
                        target="_blank"
                    >
                        <img src={Assets.telegramLogo} />
                    </a>
                </Stack>
            </Col> */}
        </div>
    );
};

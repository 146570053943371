import { icons } from "~constants";

export type WriterStepsType = {
    id: number;
    stepName: string;
    icon?:string;
};

export const writerSteps: WriterStepsType[] = [
    {
        id: 1,
        stepName: 'What do you want us to write about?',
        icon: icons.wavingHand,
    },
    {
        id: 2,
        stepName: 'How long should it be?',
        icon: icons.thinkingFace,
    },
    {
        id: 3,
        stepName: 'How should it sound? Pick your tone of voice',
        icon: icons.faceWithMonocie,
    },
    {
        id: 4,
        stepName: 'Review & edit your table of contents',
    },
    {
        id: 5,
        stepName: '',
    }
];

export const toneOfVoice = [
    {
        id: 1,
        name: 'Analytical',
    },
    {
        id: 2,
        name: 'Descriptive',
    },
    {
        id: 3,
        name: 'Formal',
    },
    {
        id: 4,
        name: 'Persuasive',
    },
    {
        id: 5,
        name: 'Narrative',
    },
    {
        id: 6,
        name: 'Informative',
    },
    {
        id: 7,
        name: 'Casual',
    },
    {
        id: 8,
        name: 'Objective',
    }
]

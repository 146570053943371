import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ExportFormat } from '~utils/export';
import './Export.styles.scss';

export type WriterStepsProps = {
    show: boolean;
    handleClose: () => void;
    handleExportClick: (format: ExportFormat) => void;
};

export const ExportModal: React.FC<WriterStepsProps> = ({
    handleClose,
    handleExportClick,
    show,
}) => {
    const { t } = useTranslation();
    const [exportFormat, setExportFormat] = useState<null | ExportFormat>(null);

    const isActive = (format: ExportFormat) => format === exportFormat;
    const handleExport = () => {
        if (exportFormat) {
            handleExportClick(exportFormat);
        }
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            contentClassName="modalContent"
        >
            <div className="writer-steps-section export-modal">
                <h1 className="step-title">Select the file type</h1>
                <div className="export-option-container">
                    <div
                        className={`export-modal export-option ${isActive('DOCX') ? 'active' : ''} `}
                        onClick={() => setExportFormat('DOCX')}
                    >
                        <img src="/assets/icons/docx-icon.svg" />
                        .DOCX
                    </div>
                    <div
                        className={`export-modal export-option ${isActive('PDF') ? 'active' : ''} `}
                        onClick={() => setExportFormat('PDF')}
                    >
                        <img src="/assets/icons/pdf-icon.svg" />
                        .PDF
                    </div>
                </div>

                <div className="action-buttons">
                    <div className="cta-btn" onClick={handleExport}>
                        Export
                    </div>
                </div>
            </div>
        </Modal>
    );
};

import React from 'react';
import IconWithText, { Status } from '~components/IconWithText/IconWithText';
import AiLikeLiHoodPercentage from '../AiLikeliHoodPercentage/AiLikeliHoodPercentage';

import './AiDetectorFooter.styles.scss';
import { useTranslation } from 'react-i18next';
interface AiDetectorFooterProps {
    status: Status;
    percentage?: number;
    whiteBg?: boolean;
}

const AiDetectorFooter: React.FC<AiDetectorFooterProps> = ({
    percentage,
    status,
    whiteBg,
}) => {
    const { t } = useTranslation();

    return (
        <div className="ai-detector-footer">
            <div className="ai-detector-footer-top">
                <span>{t('writer.ai_detection_likely')}</span>
                <div className="ai-detector-footer-top-right">
                    <IconWithText
                        status="UNDETECTED"
                        text={t('human')}
                        isBold={true}
                    />
                    <IconWithText
                        status="WARNING"
                        text={t('writer.likely_ai')}
                        isBold={true}
                    />
                    <IconWithText status="DETECTED" text="AI" isBold={true} />
                </div>
            </div>
            <div className="ai-detector-footer-bottom">
                <div
                    className={`ai-detector-footer-bottom-left ${whiteBg && 'white-bg'} `}
                >
                    <AiLikeLiHoodPercentage num={percentage} />
                </div>
                <div
                    className={`ai-detector-footer-bottom-right ${whiteBg && 'white-bg'} `}
                >
                    <IconWithText status={status} text="GPTzero" />
                    <IconWithText status={status} text="Turnitin" />
                    <IconWithText status={status} text="Writer " />
                    <IconWithText status={status} text="OpenAI" />
                    <IconWithText status={status} text="Copyleaks" />
                    <IconWithText status={status} text="Content at Scale" />
                </div>
            </div>
        </div>
    );
};

export default AiDetectorFooter;

import React, { useState } from 'react';

import ToolCard from './ToolCard/ToolCard';
import { useNavigate } from "react-router-dom";

import { ReactComponent as ScanIcon } from '~/assets/rounded-scanner.svg';
import { ReactComponent as PencilIcon } from '~/assets/dashboard-pencil.svg';
import { ReactComponent as ChromeIcon } from '~/assets/dashboard-chrome.svg';

import './DashboardTools.styles.scss';
import {
    Assets,
    CHROME_STORE_EXTENSION_URL,
} from '~constants';
import QrCodePopup from './QrCodePopup/QrCodePopup';
import { useScreenSize } from '~hooks';

export function DashboardTools() {
    const [showPopup, setShowPopup] = useState(false);
    const { isMobile } = useScreenSize();
    const navigate = useNavigate();
    const redirectUrl = `${window.location.origin}/app-redirect`;


    function handleDownloadApp() {
        if (!isMobile) {
            setShowPopup(true);
        } else {
            window.open(redirectUrl, '_blank');
        }
    }

    function handleWriterPageRedirection() {
        navigate('/writer-steps')
    }

    function handleChromeExtensionRedirection() {
        window.open(CHROME_STORE_EXTENSION_URL, '_blank');
    }

    const cardData = [
       
        
        {
            title: 'Chrome Extension',
            subTitle:
                'Button pops up into your assignments. Click to get Instant Answers on any quiz, or homework.',
            src: Assets.chromeExtensionDesktop,
            btnIcon: <ChromeIcon />,
            desktopOnly: isMobile ? true : false,
            buttonText: isMobile ? 'Available on Desktop' : 'Chrome Extension',
            cardAction: handleChromeExtensionRedirection,
        },
        {
            title: 'StudyBuddyApp',
            subTitle:
                'Simply take a picture, and get clear, step-by-step solutions instantly.',
            src: Assets.appDescriptionDesktop,
            btnIcon: <ScanIcon />,
            desktopOnly: false,
            buttonText: 'Download the App',
            cardAction: handleDownloadApp,
        },
        {
            title: 'Essay Writer',
            subTitle:
                <>Generate A+ <b>undetectable</b> essays with one click that match your unique writing style.</>,
            src: Assets.writerDescriptionDesktop,
            btnIcon: <PencilIcon />,
            buttonText: isMobile ? 'Available on Desktop' : 'Start Writing',
            // buttonText: 'Coming Soon',
            desktopOnly: isMobile ? true : false,
            isDisabled:false,
            cardAction: handleWriterPageRedirection,
        },
    ];
    return (
        <div className="tool-cards-container">
            {' '}
            {cardData?.map((data) => (
                <ToolCard
                    imgSrc={data.src}
                    title={data.title}
                    subTitle={data.subTitle}
                    buttonText={data.buttonText}
                    btnIcon={data.btnIcon}
                    desktopOnly={data.desktopOnly}
                    cardAction={data.cardAction}
                    isDisabled={!!data.isDisabled}
                />
            ))}{' '}
            <QrCodePopup
                handleClose={() => setShowPopup(false)}
                showPopup={showPopup}
                redirectUrl={redirectUrl}
            />
        </div>
    );
}

import React, { FC } from 'react';

import './Footer.styles.scss';
// import TiktokLogo from '../../../assets/TiktokLogo.svg';
import TiktokBlackLogo from '../../../assets/TiktokLogo_black.svg';
import StudybuddyLogoBlack from '../../../assets/studybuddy-logo-black.svg';
// import SocialsBlockLogo from '../../../assets/group-social-icons.svg';
// import InstaLogo from '../../../assets/InstaLogo.svg';
import InstaBlackLogo from '../../../assets/InstaLogo_black.svg';
import XBlackLogo from '../../../assets/XLogo_black.svg';
import { useTranslation } from 'react-i18next';
import '../../New_Homepage/NewFooter/NewFooter.styles.scss';
import { HashLink } from 'react-router-hash-link';
import { CHROME_STORE_EXTENSION_URL } from '~constants';
type FooterProps = {
    scrollToPricing?: () => void;
};
export const Footer: FC<FooterProps> = ({ scrollToPricing }) => {
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="footerContainer">
                <div className="footerTop">
                    <div className="footerBrand">
                        <a href="/">
                            {/* <img
                            src="/assets/images/newLogo.png"
                            alt="logo"
                            className="footerLogo"
                        /> */}
                            <img
                                src={StudybuddyLogoBlack}
                                alt="logo"
                                className="footerLogo"
                            />
                        </a>
                        <div className="socialsBlock">
                            {/* <a
                            // href="https://www.tiktok.com/@getstudybuddy.gg"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={SocialsBlockLogo} alt="socials-block" />
                        </a> */}
                            <a
                                href="https://www.tiktok.com/@getstudybuddy.gg"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={TiktokBlackLogo} alt="tiktok" />
                            </a>
                            <a
                                href="https://www.instagram.com/studybuddy.gg"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={InstaBlackLogo} alt="instagram" />
                            </a>

                            {/* <a
                            href="https://www.instagram.com/studybuddy.gg"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={XBlackLogo} alt="x" />
                        </a> */}
                        </div>
                        <div className="addressBlock">
                            <p className="addressText">
                                651 N Broad St, Suite 201, Middletown, <br />{' '}
                                19709, Delaware
                            </p>
                            <p className="addressText">
                                Contact email: support@studybuddy.gg
                            </p>
                        </div>
                    </div>
                    <div className="footerLinks">
                        <div>
                            <div className="footerListTitle">
                                {t('product')}
                            </div>
                            <ul className="footerList">
                                <li className="footerListItem">
                                    <HashLink
                                        to="/#pricing-section"
                                        className="footerLink"
                                    >
                                         {t('pricing')}
                                    </HashLink>
                                </li>
                                <li className="footerListItem">
                                    <HashLink
                                        to="/#howItWorks"
                                        className="footerLink"
                                    >
                                         {t('features')}
                                    </HashLink>
                                </li>
                                <li className="footerListItem">
                                    <a
                                        href={CHROME_STORE_EXTENSION_URL}
                                        className="footerLink"
                                        target='_blank'
                                    >
                                        {t('chrome_extension')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="footerListTitle">
                                {t('company')}
                            </div>
                            <ul className="footerList">
                                <li className="footerListItem">
                                    <HashLink
                                        to="/#testimonials-section"
                                        className="footerLink"
                                    >
                                        {t('reviews')}
                                    </HashLink>
                                </li>
                                <li className="footerListItem">
                                    <a
                                        href="/privacy-policy"
                                        className="footerLink"
                                    >
                                        {t('privacy_policy')}
                                    </a>
                                </li>
                                <li className="footerListItem">
                                    <a
                                        href="/terms-conditions"
                                        className="footerLink"
                                    >
                                        {t('terms_of_service')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="footerListTitle">{t('help')}</div>
                            <ul className="footerList">
                                <li className="footerListItem">
                                    <a
                                        href="mailto:support@studybuddy.gg"
                                        className="footerLink"
                                    >
                                        {t('contact_us')}
                                    </a>
                                </li>
                                <li className="footerListItem">
                                    <HashLink to="/#faq" className="footerLink">
                                        FAQ's
                                    </HashLink>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="footerListTitle">
                                {t('compare')}
                            </div>
                            <ul className="newFooterList">
                                <li className="newFooterListItem">
                                    <a
                                        href="http://studybuddy.gg/alternative/coursology"
                                        className="newFooterLink"
                                    >
                                        Coursology alternative
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a
                                        href="http://studybuddy.gg/alternative/photomath"
                                        className="newFooterLink"
                                    >
                                        Photomath alternative
                                    </a>
                                    <a
                                        href="http://studybuddy.gg/alternative/gauthmath"
                                        className="newFooterLink"
                                    >
                                        Gauthmath alternative
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a
                                        href="http://studybuddy.gg/alternative/photosolve"
                                        className="newFooterLink"
                                    >
                                        Photosolve alternative
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a
                                        href="http://studybuddy.gg/alternative/collegetools"
                                        className="newFooterLink"
                                    >
                                        Collegetools alternative
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a
                                        href="http://studybuddy.gg/alternative/transcript"
                                        className="newFooterLink"
                                    >
                                        Transcript alternative
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a
                                        href="http://studybuddy.gg/alternative/smartsolve"
                                        className="newFooterLink"
                                    >
                                        Smartsolve alternative
                                    </a>
                                    <a
                                        href="http://studybuddy.gg/alternative/homeworkify"
                                        className="newFooterLink"
                                    >
                                        Homeworkify alternative
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a
                                        href="http://studybuddy.gg/alternative/answersAi"
                                        className="newFooterLink"
                                    >
                                        AnswersAi alternative
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a
                                        href="http://studybuddy.gg/alternative/studyxai"
                                        className="newFooterLink"
                                    >
                                        Studyx.ai alternative
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="footerBottomContainer">
                    <hr className="footerHr" />
                    <small className="copyright">© Studybuddy 2024</small>
                </div>
            </div>
        </footer>
    );
};

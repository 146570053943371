import React, { useState } from 'react';
import BlurBackdropPopup from '~components/Modals/BlurBackdropPopup/BlurBackdropPopup';
import './AccountInfoPopup.styles.scss';
// import { Assets, icons } from '~constants';
import { ReactComponent as CrossIcon } from '~/assets/cross.svg';
import { ReactComponent as UserIcon } from '~/assets/user.svg';
import Form from 'react-bootstrap/Form';
import { useAuth, useSnackbar } from '~hooks';
import { updateUserApi } from '~components/UserInfo/EditUserInfo/EditUserInfo.functions';
import { logger } from '~utils';
import { InputField } from '~components/InputField';
import { useTranslation } from 'react-i18next';
import { inputFieldStyles } from '~components/UserInfo/EditUserInfo';
import { Loader } from '~components/Loader';

interface AccountInfoPopupProps {
    showPopup: boolean;
    handleClose: () => void;
}

const AccountInfoPopup: React.FC<AccountInfoPopupProps> = ({
    showPopup,
    handleClose,
}) => {
    const { user, updateAuthState } = useAuth();
    const [loading, setLoading] = useState(false);
    const firstName = user?.data?.attributes.given_name || '';
    const lastName = user?.data?.attributes.family_name || '';
    const email = user?.data?.attributes.email;
    const token = user?.data?.signInUserSession.idToken.jwtToken;
    const userData = { firstName, lastName, email, token };

    const { successSnackbar, errorSnackbar } = useSnackbar();

    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        firstName: userData?.firstName || '',
        lastName: userData?.lastName || '',
    });

    const onInputChange = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = async () => {
        setLoading(true)
        try {
            const res = await updateUserApi(userData.token, formData);
            if (res === 'Successfully updated name') {
                successSnackbar('User info updated successfully');
                updateAuthState()
                handleClose()
                setLoading(false)
            }
        } catch (error: any) {
            errorSnackbar('Something went wrong, try again');
            logger.error(error);
            setLoading(false)
        }
    };

    return (
        <BlurBackdropPopup
            customClass="account-info-popup"
            show={showPopup}
            handleClose={handleClose}
        >
            <div className="acc-info-container">
                <div className="acc-info-header">
                    <h1>
                        <UserIcon /> Account{' '}
                    </h1>
                    <div
                        onClick={handleClose}
                        className="close-popup-container"
                    >
                        <CrossIcon />
                    </div>
                </div>
                <div className="acc-info-field-container">
                    <div className="acc-info-field">
                        <InputField
                            type="text"
                            label={t('first_name')}
                            name="firstName"
                            placeholder={t('first_name')}
                            value={formData.firstName}
                            onChange={onInputChange}
                            style={inputFieldStyles}
                        />
                        <InputField
                            type="text"
                            label={t('last_name')}
                            name="lastName"
                            placeholder={t('last_name')}
                            value={formData.lastName}
                            onChange={onInputChange}
                            style={inputFieldStyles}
                        />
                    </div>
                    
                    <div className="acc-info-field">
                        <InputField
                            type="email"
                            label={t('Email Address')}
                            name="email"
                            placeholder="example@email.com"
                            value={userData.email}
                            onChange={() => {}}
                            style={inputFieldStyles}
                            disabled={true}
                        />
                    </div>
                </div>
                <button
                    disabled={loading}
                    onClick={onSubmit}
                    className="close-acc-info-popup"
                >
                    {loading ? <Loader color="#fff" /> : 'Save'}
                </button>
            </div>
        </BlurBackdropPopup>
    );
};

export default AccountInfoPopup;

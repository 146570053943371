export const isUserSubscribed = (billingDetails: any) => {
    if (!billingDetails) {
        // Show a loading state while data is being fetched
        return false;
    }
    // console.log('line 243 ----------->', billingDetails?.status);
    console.log('plan nick name -------->', billingDetails?.planNickName);

    if (billingDetails?.status?.split(':')[0] === 'cancels') {
        return true;
    } else if (
        billingDetails?.planNickName === null ||
        billingDetails?.planNickName === undefined
    ) {
        return true;
    } else {
        return false;
    }
};

export const isUserHaveActiveSubscription = (billingDetails: any) => {
    if (!billingDetails) {
        // Show a loading state while data is being fetched
        return false;
    }
    if (billingDetails?.status?.split(':')[0] === 'cancels') {
        return false;
    } else if (
        billingDetails?.planNickName === null ||
        billingDetails?.planNickName === undefined
    ) {
        return true;
    } else {
        return false;
    }
};

import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Header } from './Header';
import { Hero } from './Hero';
import { Institutions } from './Institutions';
import { PictureReviews } from './PictureReviews';
import { Pricing } from './Pricing';
import { HowItWorks } from './HowItWorks';
import { CompanyLogos } from './CompanyLogos';
import { Faq } from './Faq';
import { Footer } from '../Homepage/Footer';
import { StickyPricing } from './StickyPricing';
import { Register } from '~pages/Register';
import { PaymentDetails } from '~pages/PaymentDetails';
import { useAuth, useDisableBodyScroll } from '~hooks';
import { AppRoutes, CookieKeys } from '~constants';
import { firePixelsAddToCart } from '~utils/advertisingEvents';
import { NewsletterPopup } from '~components/Modals/Newsletter';
import { setCookie } from '~utils';
import { CTA } from '~pages/WriterTool/CTA/CTA';
import { Numbers } from './Numbers';
import { Helmet } from 'react-helmet';

import NewFeature from './NewFeatures/NewFeature';
import NewReviews from './NewReviews/NewReviews';
import { WriterFeatures } from './WriterFeatures';
import './WriterTool.styles.scss';
export const WriterTool = () => {
    const [showPopupCheckout, setShowPopupCheckout] = useState(false);
    const [checkoutPage, setCheckoutPage] = useState(false);
    const [isNewsLetterPopupShow, setIsNewsLetterPopupShow] = useState(false);
    // const [disturbancePopup, setDisturbancePopup] = useState(false);

    const { updateAuthState, isAuthenticated } = useAuth();

    const checkAuth = async () => {
        console.log('priv auth >>>:');
        await updateAuthState();
    };

    const checkReferral = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const ref = urlParams.get('ref');
        const fbclid = urlParams.get('fbclid');
        if (ref) {
            setCookie(CookieKeys.referralCookie, ref, 60);
        }
        if (fbclid) {
            setCookie(CookieKeys.fbClickId, fbclid, 60);
        }
    };

    const navigate = useNavigate();
    useDisableBodyScroll(showPopupCheckout);

    const handlePopupCheckout = () => {
        console.log('isAuthenticated >>>', isAuthenticated);
        if (isAuthenticated) {
            navigate(AppRoutes.Dashboard);
            return;
        }

        firePixelsAddToCart();

        setShowPopupCheckout(true);
    };

    const handlePopupCheckoutClose = () => {
        setShowPopupCheckout(false);
    };

    const handleCheckoutPage = () => {
        setCheckoutPage(!checkoutPage);
    };

    const handleOpenNewsLetterPopup = () => {
        setIsNewsLetterPopupShow(true);
    };

    const handleCloseNewsLetterPopup = () => {
        localStorage.setItem('isNewsletterAvailable', 'false');
        setIsNewsLetterPopupShow(false);
    };

    const checkIsNewsletterAvailable = () => {
        const isNewsletterAvailable = localStorage.getItem(
            'isNewsletterAvailable',
        );
        if (!isNewsletterAvailable) {
            handleOpenNewsLetterPopup();
        }
    };

    const [isPricingInView, setIsPricingInView] = useState(false);

    useEffect(() => {
        checkReferral();
        checkAuth();
        /* const DisturbanceTimer = setTimeout(disturbancePopupHandler, 500); */
        const NewsLetterTimer = setTimeout(checkIsNewsletterAvailable, 60000);
        return () => {
            clearTimeout(NewsLetterTimer);
        };
    }, []);

    const scrollToPricing = () => {
        let pricingEle = document.getElementById('pricing-section');
        pricingEle?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <Helmet>
                <meta
                    property="og:image"
                    content="https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/share-link-writer.png"
                />
            </Helmet>

            <div className="homepage writer-tool">
                <Header scrollToPricing={scrollToPricing} />
                <Hero
                    setIsPricingInView={setIsPricingInView}
                    scrollToPricing={scrollToPricing}
                />
                <CompanyLogos />
                <PictureReviews />
                <Numbers />
                <HowItWorks />
                <Pricing
                    handleCTA={handlePopupCheckout}
                    setIsPricingInView={setIsPricingInView}
                />
                <NewFeature />
                <NewReviews />
                <WriterFeatures />
                <Faq />
                <Institutions />
                <CTA
                    handleCTA={handlePopupCheckout}
                    setIsPricingInView={setIsPricingInView}
                />
                <Footer scrollToPricing={scrollToPricing} />
                <StickyPricing
                    showCheckout={showPopupCheckout}
                    handleCTA={handlePopupCheckout}
                    isPricingInView={isPricingInView}
                />
                <div
                    className={`popup-checkout ${
                        showPopupCheckout ? 'active' : ''
                    } ${checkoutPage ? 'checkout-page' : ''}`}
                >
                    {checkoutPage ? (
                        <PaymentDetails isPopupCheckout />
                    ) : (
                        <Register
                            isPopupCheckout
                            handleCheckoutPage={handleCheckoutPage}
                        />
                    )}
                </div>
                {showPopupCheckout && (
                    <div
                        className="popup-checkout-overlay"
                        onClick={handlePopupCheckoutClose}
                    ></div>
                )}
                <NewsletterPopup
                    show={isNewsLetterPopupShow && !showPopupCheckout}
                    handleClose={handleCloseNewsLetterPopup}
                    isBlackTheme
                />
            </div>
        </>
    );
};

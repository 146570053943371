import React, { FC, useEffect, useState } from 'react';
import { useAuth, useScreenSize } from '~hooks';
import './DashboardKnowledges.styles.scss';
import bookIcon from '../../../assets/book-icon.svg';
import extensionPreview from '../../../assets/extension-preview.svg';
import extensionPreviewMobile from '../../../assets/extension-preview-mobile.svg';
import PlayIcon from '../../../assets/play.svg';
import HelpIcon from '../../../assets/help-dark-blue.svg';
import {
    CHROME_EXTENSION_DOWNLOAD_URL,
    CHROME_STORE_EXTENSION_URL,
    HELP_CENTER_URL,
    icons,
} from '~constants';
import { ContactUsPopup } from '~components/Modals/ContactUsPopup';
import { WatchTutorialPopup } from '~components/Modals/WatchTutorialPopup';
import { Referral } from '~components/Referral';
import { BillingDetails, getStripeUserDetails } from '~components/PaymentPlan';
import { useTranslation } from 'react-i18next';

type DashboardKnowledgesProps = {
    showOnboarding: boolean;
    setShowOnboarding: (show: boolean) => void;
};

export const ExtensionIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="24"
            viewBox="0 0 26 24"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.0242 9.78386L22.0106 5.53846C22.0106 4.16552 20.8052 2.9401 19.4338 2.9401H14.6338C14.6398 0.938719 13.0032 0 10.9867 0C8.97177 0 7.34113 0.938718 7.34113 2.88886C7.34113 2.90694 3.51922 2.9401 3.51922 2.9401C2.1508 2.9401 0.98584 4.16553 0.98584 5.53846L1.03859 9.62411C3.03093 9.64823 4.64047 11.2156 4.64047 13.1522C4.64047 15.0872 3.03243 16.6531 1.03859 16.6787V21.5133C1.03859 22.8863 2.14779 24 3.51771 24H19.4338C20.8037 24 22.0634 22.8863 22.0634 21.5133L22.0468 16.8173C23.798 16.6078 25.0145 15.1219 25.0145 13.3014C25.0145 11.4703 23.789 9.98279 22.0242 9.78386Z"
                fill="#0E153A"
            />
        </svg>
    );
};

const DashboardKnowledges: FC<DashboardKnowledgesProps> = (props) => {
    const { setShowOnboarding } = props;
    const { isMobile } = useScreenSize();
    const { user, token } = useAuth();
    // const navigate = useNavigate();

    const [isShowContactUsPopUp, setIsShowContactUsPopUp] = useState(false);
    const [isShowTutorialPopUp, setIsShowTutorialPopUp] = useState(false);
    const [billingsData, setBillingData] = useState<any | null>(null);
    const { t } = useTranslation();

    // const onDownloadNowClick = () => {
    //     window.open(CHROME_EXTENSION_DOWNLOAD_URL);
    // };

    const onWatchTutorialClickHandler = () => {
        window.open(CHROME_STORE_EXTENSION_URL, '_blank');
    };
    const onContactUsClickHandler = () => {
        setIsShowContactUsPopUp(true);
    };

    const onCancelPopupCloseHandler = () => {
        setIsShowContactUsPopUp(false);
    };
    const onCancelTutorialPopupCloseHandler = () => {
        setIsShowTutorialPopUp(false);
    };

    useEffect(() => {
        const getUserDetails = async () => {
            let billingData = await getStripeUserDetails(
                user?.data?.signInUserSession?.idToken?.jwtToken,
                String(user?.data?.attributes['custom:stripeCustomerID']),
                t,
            );
            if (billingData) {
                setBillingData(billingData);
            }
        };
        getUserDetails();
        // }, [billingsData])
    }, []);

    return (
        <div className="dashboard-knowledges">
            <ContactUsPopup
                open={isShowContactUsPopUp}
                onClose={onCancelPopupCloseHandler}
            />
            <WatchTutorialPopup
                open={isShowTutorialPopUp}
                onClose={onCancelTutorialPopupCloseHandler}
            />

            {/* install extension component */}
            {/* {!isMobile && (
                <div className="extension-container">
                    <div className="extension-details">
                        <h4 className="extension-title">
                            <ExtensionIcon /> Install Extension
                        </h4>
                        <p className="extension-subtitle">
                            Click below to install our Google Chrome Extension
                        </p>
                        <div className="extension-buttons-container">
                            <button
                                onClick={() => setShowOnboarding(true)}
                                className="extension-download-button"
                            >
                                <img src={bookIcon} alt="book_icon" />
                                Installation Guide
                            </button>
                            <button
                                onClick={onWatchTutorialClickHandler}
                                className="watchGifs-button"
                            >
                                <img src={icons.chromeBlue} alt="watch-gif" />
                                Chrome Extension
                            </button>
                        </div>
                    </div>
                    <div className="extension-preview">
                        <img
                            className="ext-img"
                            src={
                                isMobile
                                    ? extensionPreviewMobile
                                    : extensionPreview
                            }
                            alt="extension_preview"
                        />
                    </div>
                </div>
            )} */}

            {/* how to use StudyBuddy component */}
            {/* <div className="howTo-container">
                <h4 className="howTo-title">How to use StudyBuddy?</h4>
                <p className="howTo-subtitle">Watch our step by step guide</p>
                <button
                    onClick={onWatchTutorialClickHandler}
                    className="watchGifs-button"
                >
                    <img src={PlayIcon} alt="watch-gif" />
                    Watch Tutorial
                </button>
            </div> */}

            {/* referral content */}
            <Referral />

            {/* Need Help component */}
            <div className="help-container">
                <div className="help-text-container">
                    <div className="help-text">
                        <h4 className="help-title">Need Help?</h4>
                    </div>
                </div>
                <a
                    href={HELP_CENTER_URL}
                    target="_blank"
                    className="help-center-button"
                >
                    <img src={HelpIcon} alt="need_help?" />
                    Help Center
                </a>
            </div>
        </div>
    );
};

export default DashboardKnowledges;

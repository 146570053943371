import { getDocument } from 'pdfjs-dist';
import mammoth from 'mammoth';

const pdfjsLib = require('pdfjs-dist');
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

export const readPdfFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = async function () {
            try {
                const typedArray = new Uint8Array(
                    fileReader.result as ArrayBuffer,
                );
                const pdf = await getDocument(typedArray).promise;

                let textContent = '';
                for (let i = 1; i <= pdf.numPages; i++) {
                    const page = await pdf.getPage(i);
                    const text = await page.getTextContent();
                    text.items.forEach((item: any) => {
                        textContent += item.str + ' ';
                    });
                }

                resolve(textContent);
            } catch (error) {
                reject(error);
            }
        };

        fileReader.onerror = reject;
        fileReader.readAsArrayBuffer(file);
    });
};

export const readDocxFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = async function (event) {
            try {
                const arrayBuffer = event?.target?.result as ArrayBuffer;
                const result = await mammoth.extractRawText({ arrayBuffer });
                resolve(result.value);
            } catch (error) {
                reject(error);
            }
        };

        fileReader.onerror = reject;
        fileReader.readAsArrayBuffer(file);
    });
};

export const readTextFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = async function (event) {
            try {
                const result =  event?.target?.result;
                resolve(result as string);
            } catch (error) {
                reject(error);
            }
        };

        fileReader.onerror = reject;
        fileReader.readAsText(file);
    });
};

export const readFileData = async (file: File): Promise<string> => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    if (fileType === 'pdf') {
        return await readPdfFile(file);
    } else if (fileType === 'docx') {
        return await readDocxFile(file);
    }  else if (fileType === 'txt') {
        return await readTextFile(file);
    }
     else {
        throw new Error(
            'Unsupported file format. Only PDF and DOCX are allowed.',
        );
    }
};

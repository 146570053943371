import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '~constants';
import Logo from '~/assets/study-buddy-writer-logo.svg';
import { ReactComponent as HomeIcon } from '~/assets/home-icon.svg';
import { ReactComponent as ContentIcon } from '~/assets/content-icon.svg';
import { ReactComponent as NinjaIcon } from '~/assets/ninja-icon.svg';
import { ReactComponent as ScanIcon } from '~/assets/scan-icon.svg';
import { ReactComponent as DocIcon } from '~/assets/doc-icon.svg';
import NavIcon from '~/assets/writer-nav-icon.svg';
import './Sidebar.styles.scss';

export const WriterDashboardSidebar = () => {
    const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);
    const { t } = useTranslation();

    const handleToggle = () => {
        setIsSidebarMinimized(!isSidebarMinimized);
    };

    return (
        <aside
            className={`writer-dashboard-sidebar ${isSidebarMinimized ? 'minimized' : ''}`}
        >
            <header>
                <i>
                    <img src={Logo} alt="Logo" className="logo" />
                </i>
                <i>
                    <img
                        src={NavIcon}
                        onClick={handleToggle}
                        alt="Toggle sidebar"
                        className="toggle-icon"
                    />
                </i>
            </header>
            <ul>
                <li>
                    <NavLink
                        to={AppRoutes.WriterSteps}
                        className={({ isActive }) => (isActive ? 'active' : '')}
                        end
                    >
                        <i>
                            <HomeIcon />
                        </i>
                        <span>{t('Home')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={AppRoutes.WriterContentgenerator}
                        className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                        <i>
                            <ContentIcon />
                        </i>
                        <span>{t('Content Generator')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={AppRoutes.WriterAITextHumanizer}
                        className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                        <i>
                            <NinjaIcon />
                        </i>
                        <span>{t('AI Text Humanizer')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={AppRoutes.WriterAIDetector}
                        className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                        <i>
                            <ScanIcon />
                        </i>
                        <span>{t('AI Detector')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={AppRoutes.WriterDocuments}
                        className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                        <i>
                            <DocIcon />
                        </i>
                        <span>{t('Documents')}</span>
                    </NavLink>
                </li>
            </ul>
            <footer>
                <a href="https://help.studybuddy.gg/" target='_blank'>
                    <span>? </span>
                    <span>{t('help_center')}</span>
                </a>
            </footer>
        </aside>
    );
};

import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Assets, icons } from '~constants';
import { onboardingSteps } from '../Onboarding.constants';
import type { OnboardingStepProps } from './OnboardingStep.types';
import './OnboardingStep.styles.scss';
import { useAuth, useScreenSize, useSnackbar } from '~hooks';
import { parseTextWithStrongTag } from '~utils/parseDynamicText';
import { logger } from '~utils';
import SemesterDealUpsell from '../SemesterDealUpsell';
import TurboModeCrossSell from '../TurboModeCrossSell';
import OnboardingButton from './OnboardingButton/OnboardingButton';

export const OnboardingStep: FC<OnboardingStepProps> = (props: any) => {
    const { isMobile, isTablet } = useScreenSize();
    const {
        isExtensionInstalled,
        step,
        onLeftButtonClick,
        onRightButtonClick,
        increaseStep,
        isTurboModeActive,
        isSubscribedToSemester,
    } = props;
    const { user } = useAuth();
    const [referralUrl, setReferralUrl] = useState<string>('');
    const { successSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const isShowExtenstionBtn = step === 1 && !isMobile && !isTablet;

    const isShowDoneBtn =
        step === 3 && isTurboModeActive && isSubscribedToSemester;
    console.log('is show done button ------------>', isShowDoneBtn);

    const isShowNextBtn =
        step === 3 && (!isTurboModeActive || !isSubscribedToSemester);

    const isShowSemesterUpsell = step === 4;
    const isShowTurboCrossSell = step === 5;

    useEffect(() => {
        let code = user?.data?.attributes['custom:stripeCustomerID'];
        if (!code || !code.startsWith('cus_')) {
            logger.error(
                'No Stripe Customer ID found when trying to create referral code' as any,
            );
        } else {
            code = code.replace('cus_', '');
            setReferralUrl(`https://studybuddy.gg?ref=${code}`);
        }
    }, [user]);

    return (
        <>
            <div className="onboarding-steps-container">
                {onboardingSteps.map((onboardingStep) => {
                    return (
                        <div
                            className={`onboarding-step ${onboardingStep.id === step ? 'active' : ''} ${step === 3 ? 'step_3' : ''}`}
                            key={onboardingStep.id}
                        >
                            <div className="step-info">
                                <h1
                                    dangerouslySetInnerHTML={{
                                        __html: parseTextWithStrongTag(
                                            onboardingStep.title,
                                        ),
                                    }}
                                ></h1>
                                {onboardingStep.bulletPoints && (
                                    <ul>
                                        {onboardingStep.bulletPoints.map(
                                            (point) => (
                                                <li
                                                    style={{ fontSize: '16px' }}
                                                    key={point}
                                                    dangerouslySetInnerHTML={{
                                                        __html: parseTextWithStrongTag(
                                                            point,
                                                        ),
                                                    }}
                                                ></li>
                                            ),
                                        )}
                                    </ul>
                                )}
                                {onboardingStep.subBulletPoints && (
                                    <ul>
                                        {onboardingStep.subBulletPoints.map(
                                            (subBulletPoint) => (
                                                <li key={subBulletPoint.title}>
                                                    <strong>
                                                        {subBulletPoint.title}
                                                    </strong>
                                                    <ul>
                                                        {subBulletPoint.bulletPoints.map(
                                                            (point) => (
                                                                <li
                                                                    key={point}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: parseTextWithStrongTag(
                                                                            point,
                                                                        ),
                                                                    }}
                                                                ></li>
                                                            ),
                                                        )}
                                                    </ul>
                                                </li>
                                            ),
                                        )}
                                    </ul>
                                )}
                            </div>
                            <div
                                className={`onboarding-step__image ${step === 3 ? 'step_3' : ''}`}
                            >
                                {onboardingStep.assetType === 'video' && (
                                    <video controls>
                                        <source
                                            src={onboardingStep.assetPath}
                                        />
                                    </video>
                                )}
                                {onboardingStep.assetType === 'image' && (
                                    <img src={onboardingStep.assetPath} />
                                )}

                                <img
                                    className={`onboarding-arrow ${isExtensionInstalled && 'hide'} `}
                                    src={icons.OnboardingArrow}
                                    alt="OnboardingArrow"
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
            <OnboardingButton
                increaseStep={increaseStep}
                isShowDoneBtn={isShowDoneBtn}
                isShowExtenstionBtn={isShowExtenstionBtn}
                isShowSemesterUpsell={isShowSemesterUpsell}
                isShowTurboCrossSell={isShowTurboCrossSell}
                onLeftButtonClick={onLeftButtonClick}
                onRightButtonClick={onRightButtonClick}
                referralUrl={referralUrl}
                step={step}
                isExtensionInstalled={isExtensionInstalled}
                isSubscribedToSemester={isSubscribedToSemester}
                isTurboModeActive={isTurboModeActive}
            />
        </>
    );
};

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    forgotPassword,
    forgotPasswordSubmit,
    formValidation,
} from './ForgetPassword.functions';
import { useScreenSize, useSnackbar } from '~hooks';
import { AppRoutes, defaultErrorMessage } from '~constants';
import { Header } from '~components/Header';
import { SubmitEmail } from './SubmitEmail';
import { SubmitPassword } from './SubmitPassword';

import './ForgetPassword.styles.scss';
import { useTranslation } from 'react-i18next';

export const ForgetPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [stepTwo, setStepTwo] = useState(false);
    const [formData, setFormData] = React.useState({
        email: '',
        code: '',
        password: '',
        confirmPassword: '',
    });
    const [formErrors, setFormErrors] = useState<any>();

    const { errorSnackbar, successSnackbar } = useSnackbar();
    const { isMobile } = useScreenSize();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const onChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const onForgetPassword = async () => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        setIsLoading(true);
        if (!formData.email) {
            setIsLoading(false);
            setFormErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'Email is required',
            }));
            errorSnackbar('Please enter your email');
            return;
        } else if (!emailRegex.test(formData.email)) {
            setIsLoading(false);
            setFormErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'Invalid email format',
            }));
            errorSnackbar('Please enter a valid email address');
            return;
        } else {
            setFormErrors(null);
            const response = await forgotPassword(formData.email.toLowerCase());
            if (response) {
                setIsLoading(false);
                setStepTwo(true);
            } else {
                setIsLoading(false);
                errorSnackbar(defaultErrorMessage);
            }
        }
    };

    const onForgetPasswordSubmit = async (
        e: React.FormEvent<HTMLFormElement>,
    ) => {
        e.preventDefault();
        setIsLoading(true);
        const { email, code, password, confirmPassword } = formData;
        const errors = formValidation(formData);
        if (errors) {
            setIsLoading(false);
            setFormErrors(errors);
            return;
        } else {
            setFormErrors(null);
            try{
                const response = await forgotPasswordSubmit(
                    email,
                    code,
                    password,
                    confirmPassword,
                );
                if (response) {
                    setIsLoading(false);
                    successSnackbar('Password changed successfully');
                    setTimeout(() => {
                        navigate(AppRoutes.Login);
                    }, 2000);
                } else {
                    setIsLoading(false);
                    errorSnackbar(defaultErrorMessage);
                }
            }
            catch(err:any){
                console.log(err)
                errorSnackbar(err.message || defaultErrorMessage);
            }
        }
    };

    return (
        <div className="forget-password-page">
            {/* <Header logoAlign="center" /> */}
            <div className="forget-password-section">
                <div className="forget-password-header">
                    <h1>
                        {stepTwo ? t('Reset Password') : t('forgot_password')}
                    </h1>
                    {stepTwo ? (
                        <p>
                            Check your e-mail for the <span>reset code</span>.
                        </p>
                    ) : isMobile ? (
                        <p>
                            Enter the email address associated with your account
                            and we will send you a <span>reset code.</span>
                        </p>
                    ) : (
                        <p>
                            Enter the email associated with your account and we
                            will send you a <span> reset code.</span>
                        </p>
                    )}
                </div>

                {!stepTwo ? (
                    <SubmitEmail
                        onChange={onChange}
                        formData={formData}
                        formErrors={formErrors}
                        onForgetPassword={onForgetPassword}
                        isLoading={isLoading}
                    />
                ) : (
                    <SubmitPassword
                        onChange={onChange}
                        formData={formData}
                        formErrors={formErrors}
                        onForgetPasswordSubmit={onForgetPasswordSubmit}
                        onResend={onForgetPassword}
                        isLoading={isLoading}
                    />
                )}
            </div>
        </div>
    );
};

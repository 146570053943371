import format from 'string-format';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AlertIcon from '../../assets/alert-circle.svg';
import AlertIconBig from '../../assets/alert-circle-big.svg';
import { useAuth, useScreenSize, useSnackbar } from '~hooks';
import { covertTimestampToDate, logger } from '~utils';
import { bannerMessages } from './Banner.constants';
import { getStripeUserDetails, reSubscription } from '~components/PaymentPlan';
import './Banner.styles.scss';
import { useTranslation } from 'react-i18next';

export const Banner = () => {
    const [isBannerActive, setBannerActive] = useState(false);
    const [bannerMsg, setbannerMsg] = useState('');
    const { isMobile } = useScreenSize();
    const [updateCardUrl, setUpdateCardUrl] = useState('');

    const { user, planStatus, token } = useAuth();
    const { successSnackbar, infoSnackbar, errorSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);

    const firstSeptember = 1725152400;
    const { t } = useTranslation();

    const handleReSubscribe = async () => {
        setLoading(true);
        try {
            const customerId =
                user?.data?.attributes['custom:stripeCustomerID'];

            if (planStatus !== 'active') {
                infoSnackbar('Resubscribing...');
                const res = await reSubscription(token, customerId);

                if (res === 'Success') {
                    successSnackbar('Subscription started successfully');
                }
                setBannerActive(false);
                window.location.reload();
            } else {
                errorSnackbar('Something went wrong, try again');
            }
        } catch (error: any) {
            logger.error(error);
            toast.error('Something went wrong, try again');
        }
        setLoading(false);
    };

    const handleUpdateCard = () => {
        window.open(updateCardUrl, '_self');
    };

    useEffect(() => {
        console.log(planStatus, 'planStatus');
        // "paused: 1725231961"
        if (planStatus && planStatus !== 'active') {
            if (planStatus.includes('paused')) {
                const timeStamp = parseInt(planStatus.split(': ')[1]);
                if (timeStamp === firstSeptember) {
                    return;
                }
            }
            setBannerActive(true);
            if (planStatus.startsWith('cancels')) {
                const timestamp = parseInt(planStatus.split(': ')[1]);
                const date = covertTimestampToDate(timestamp);
                const msg = format(bannerMessages.willcancel, { date });
                setbannerMsg(msg);
            } else if (planStatus === 'failed') {
                // If the last payment failed we prompt the user to update the card
                const customerId =
                    user?.data?.attributes['custom:stripeCustomerID'];
                // Get the stripe user details for the latest card details
                getStripeUserDetails(token, customerId, t)
                    .then((response) => {
                        if (response && response.updatePaymentUrl) {
                            setUpdateCardUrl(response.updatePaymentUrl);
                        } else {
                            throw new Error('No payment URL');
                        }
                    })
                    .catch((err) => {
                        setUpdateCardUrl('/dashboard');
                    });
                setbannerMsg(bannerMessages.failed);
            } else if (planStatus.startsWith('paused')) {
                setbannerMsg(bannerMessages.paused);
            } else {
                setbannerMsg(bannerMessages.cancelled);
            }
        }
    }, [planStatus]);

    return (
        <>
            <div className={`banner ${isBannerActive ? t('active') : ''}`}>
                <div className="banner-msg-body">
                    <img
                        src={isMobile ? AlertIconBig : AlertIcon}
                        alt="alert"
                    />
                    <p>{bannerMsg}</p>
                </div>
                {planStatus === 'failed' ? (
                    <button onClick={handleUpdateCard}>
                        {t('update_card')}
                    </button>
                ) : (
                    <button onClick={handleReSubscribe} disabled={loading}>
                        {t('resubscribe')}
                    </button>
                )}
            </div>
        </>
    );
};

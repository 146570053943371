import { Link } from 'react-router-dom';
import { Container, Form } from 'react-bootstrap';

import { icons, TERMS_OF_SERVICE_URL } from '~constants';
import { inputFieldStyles } from './Register.constants';
import { useState, type FC } from 'react';
import { checkRegisterDisable } from '~pages/Register/Register.functions';
import type { RegisterFormProps } from './RegisterForm.types';
import { InputField } from '~components/InputField';
import { Loader } from '~components/Loader';
import {ReactComponent as BackIcon} from '~assets/white-circle-arrow.svg';

import './RegisterForm.styles.scss';
import { SocialLogins } from '~components/SocialLogins';
import { useScreenSize } from '~hooks';
import { useTranslation } from 'react-i18next';

export const RegisterForm: FC<RegisterFormProps> = (props) => {
    const {
        onChange,
        formData,
        formErrors,
        onEmailBlur,
        onSubmit,
        isButtonLoading,
        isEmailExist,
        handleRegisterPopupClose,
        showRegisterPopup,
    } = props;

    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPassword: false,
    });

    const isCtaDisable: boolean = checkRegisterDisable(
        formErrors,
        isEmailExist,
    );
    const { isMobile } = useScreenSize();

    const onTermsClick = () => {
        window.open(TERMS_OF_SERVICE_URL);
    };

    const { t } = useTranslation();

    const handleShowPasswordToggle = (key: 'password' | 'confirmPassword') => {
        setShowPassword(() => {
            return { ...showPassword, [key]: !showPassword[key] };
        });
    };

    return (
        <div
            className="register-form
          "
        >
            {showRegisterPopup && (
                <div
                    className="close-register-popup"
                    onClick={handleRegisterPopupClose}
                >
                    <BackIcon />
                    {/* <img src={icons.whiteCircleArrow} alt="back" /> */}
                </div>
            )}

            <div className={isMobile ? 'signup-left-align' : 'signup-desktop'}>
                {/* {!isMobile && <p className="small-sign-up">{t('sign_up')}</p>} */}
                <h1>{t('create_new_account')}</h1>
                <h3>
                    {t('already_a_member')}{' '}
                    <Link to="/login">{t('login')}</Link>
                </h3>
            </div>
            <Form className="form w-100" onSubmit={onSubmit}>
                <div className="form-field-container">
                    <InputField
                        name="email"
                        type="email"
                        label={t('email')}
                        id="emailInput"
                        value={formData.email}
                        onChange={onChange}
                        placeholder={t('email')}
                        style={inputFieldStyles}
                        error={formErrors?.email}
                        onBlur={onEmailBlur}
                    />
                    <div className="input-icon-container">
                        <InputField
                            name="password"
                            type={showPassword.password ? 'text' : 'password'}
                            label={t('password')}
                            minLength={8}
                            value={formData.password}
                            onChange={onChange}
                            placeholder={t('password')}
                            style={inputFieldStyles}
                            error={formErrors?.password}
                        />
                        <img
                            src={icons.eyeIcon}
                            alt="eye"
                            onClick={() => handleShowPasswordToggle('password')}
                        />
                    </div>
                    <div className="input-icon-container">
                        <InputField
                            name="confirmPassword"
                            type={
                                showPassword.confirmPassword
                                    ? 'text'
                                    : 'password'
                            }
                            label={t('confirm_password')}
                            minLength={8}
                            value={formData.confirmPassword || ''}
                            onChange={onChange}
                            placeholder={t('confirm_password')}
                            style={inputFieldStyles}
                            error={formErrors?.confirmPassword}
                        />
                        <img
                            src={icons.eyeIcon}
                            alt="eye"
                            onClick={() =>
                                handleShowPasswordToggle('confirmPassword')
                            }
                        />
                    </div>
                </div>
                <button
                    className="cta-btn"
                    type="submit"
                    disabled={isCtaDisable || isButtonLoading}
                >
                    {isButtonLoading ? (
                        <Loader color="#fff" />
                    ) : (
                        <span>
                            {isEmailExist ? t('Continue') : t('Continue')}
                        </span>
                    )}
                </button>
            </Form>
            <div className="divider">
                <span>{t('or')}</span>
            </div>
            <div className="social-logins-section">
                <SocialLogins isGoogleSignup />
            </div>
            {isMobile && (
                <p className="terms">
                    {t('by_creating')}
                    <span onClick={onTermsClick}>{t('agree_to_terms')}</span>
                </p>
            )}
            {!isMobile && (
                <p className="terms">
                    {t('by_creating')}
                    <span onClick={onTermsClick}>{t('agree_to_terms')}</span>
                </p>
            )}
        </div>
    );
};

import React, { useEffect, useState } from 'react';
import { marked } from 'marked';
import './contentEditable.styles.scss';
import { convertMarkdownToHtml, markdownRender } from '~utils';

interface ContentEditableDivProps {
    text: string;
    isHighlighted?: boolean;
}

const ContentEditableDiv: React.FC<ContentEditableDivProps> = ({
    text,
    isHighlighted,
}) => {
    const [transformedHtml, setTransformedHtml] = useState('');

    useEffect(() => {
        const handleDataTransform = async () => {
            const htmlContent = await convertMarkdownToHtml(text);
            setTransformedHtml(htmlContent);
        };

        handleDataTransform();
    }, [text]);

    return (
        <div
            contentEditable={false}
            id="highlightedChunkContainer"
            className={`contentEditableDiv ${isHighlighted && 'highlight'}`}
        >
            <mark dangerouslySetInnerHTML={{ __html: transformedHtml }}></mark>
        </div>
    );
};

export default ContentEditableDiv;

import React, { useState } from 'react';
import styles from './AccountDelete.module.scss';

export const AccountDelete: React.FC = () => {
  const [userName, setUserName] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [deletionReason, setDeletionReason] = useState<string>('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const formData = {
      userName,
      userEmail,
      deletionReason,
    };

    try {
      // Replace this URL with your Google App Script or backend endpoint
      /* const response = await fetch('<YOUR_GOOGLE_SCRIPT_URL>', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      }); */
      
        alert('Request submitted successfully');
        setUserName('');
        setUserEmail('');
        setDeletionReason('');
    } catch (error) {
      console.error('Error:', error);
      alert('Something went wrong.');
    }
  };

  return (
    <div className={styles.container}>
      <h1>Request Account Deletion</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <label>
          User Name:
          <input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            required
          />
        </label>

        <label>
          Email:
          <input
            type="email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            required
          />
        </label>

        <label>
          Reason for Deletion:
          <textarea
            value={deletionReason}
            onChange={(e) => setDeletionReason(e.target.value)}
            required
          />
        </label>

        <button type="submit" className={styles.ctaButton}>
          Submit Request
        </button>
      </form>
    </div>
  );
};

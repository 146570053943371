import React from 'react';
import Form from 'react-bootstrap/Form';
import { ReactComponent as NinjaIcon } from '~/assets/ninja-icon.svg';
import { ReactComponent as ReWriteIcon } from '~/assets/rewrite.svg';
import { ReactComponent as ExportIcon } from '~/assets/export.svg';

import './ContentGeneratorHeader.styles.scss';
import { useTranslation } from 'react-i18next';
interface ContentGeneratorHeaderProps {
    onHumanize?: () => void;
    onExport?: () => void;
    onRewrite?:()=>void;
    wordsCount:number
}



const ContentGeneratorHeader:React.FC<ContentGeneratorHeaderProps> = ({onHumanize,onRewrite, onExport, wordsCount}) => {
    const { t } = useTranslation();
    return (
        <div className="content-generator-header-container">
            <h1>{t('writer.generated_outline')}</h1>
            <div className="header-controller">
                {/* <Form.Switch type="switch" label="Combine View" /> */}
                <div className="wordCount">{`Total Words: ${wordsCount}`}</div>
                <button className="humanizeButton" onClick={onHumanize}>
                    <NinjaIcon />
                    {t('writer.humanize_button')}
                </button>
                 <button className="rewriteButton" onClick={onRewrite}>
                    <ReWriteIcon />
                    {t('writer.rewrite')}{' '}
                </button>
                <button className="exportButton" onClick={onExport}>
                    <ExportIcon />
                    {t('writer.export')}{' '}
                </button>
            </div>
        </div>
    );
};

export default ContentGeneratorHeader;

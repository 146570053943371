import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Loader } from '~components/Loader';

const AppRedirect: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // window.opera is cast as (window as any).opera because TypeScript does not recognize it by default.
    const userAgent: string = navigator.userAgent || navigator.vendor || (window as any).opera;
    
    // Similarly, window.MSStream is cast as (window as any).MSStream.
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      window.location.href = "https://apps.apple.com/us/app/studybuddyplus/id6670798354";
    } 
    // TODO: need to redirect to android play store once app is published 
    // else if (/android/i.test(userAgent)) {
    //   window.location.href = "";
    // } 
    else {
      navigate('/')
    }
  }, []);

  return <Loader />
};

export default AppRedirect;



import { API, Auth } from 'aws-amplify';
import type { SignUpFormData, SignUpFormErrors } from './Register.types';
import { logger } from '~utils';
import { API_NAME_STRIPE_AWS, USER_EMAIL_CHECK_API } from '~constants';

export const signUp = async (formData: SignUpFormData) => {
    const { password, email } = formData;
    const lowerCaseEmail = email.toLocaleLowerCase();
    try {
        Auth.configure({
            mandatorySignIn: true,
        });
        const { user } = await Auth.signUp({
            username: lowerCaseEmail,
            password,
            attributes: {
                given_name: '',
                family_name: '',
                email: lowerCaseEmail,
            },
            autoSignIn: {
                enabled: true,
            },
        });
        logger.debug('register user ___', user);
    } catch (error: any) {
        logger.error(error);
        throw new Error(error.message);
    }
};

export const isEmailAlreadyUsedHelper = async (email: string) => {
    try {
        const response = await API.post(
            API_NAME_STRIPE_AWS,
            USER_EMAIL_CHECK_API,
            {
                body: {
                    email,
                },
            },
        );

        logger.debug('check email response: >>', response);

        if (response?.error === 'This email already exists in Cognito') {
            return true;
        } else {
            return false;
        }
    } catch (err: any) {
        return true;
    }
};

export const checkRegisterDisable = (
    formErrors: SignUpFormErrors,
    isEmailExist: boolean | undefined,
): boolean => {
    if (isEmailExist == true) {
        return false;
    }
    return !!formErrors?.email || !!formErrors?.password;
};

export const formValidation = (formData: SignUpFormData) => {
    const { email, password, confirmEmail, confirmPassword } = formData;
    const errors = {
        email: '',
        password: '',
        confirmEmail: '',
        confirmPassword:''
    };
    if (!email) {
        errors.email = 'Email is required';
    }
    if (!password) {
        errors.password = 'Password is required';
    }
    if (password && !confirmPassword) {
        errors.confirmPassword = 'Confirm Password is required';
    }
    if (password && password.length < 8) {
        errors.password = 'Password must be at least 8 characters';
    }
    if (confirmPassword && confirmPassword.length < 8) {
        errors.password = 'confirm Password must be at least 8 characters';
    }
    if (password && confirmPassword && password.trim() !== confirmPassword?.trim()) {
        errors.confirmPassword = 'Passwords do not match';
    }
    if (Object.values(errors).some((error) => error !== '')) {
        return errors;
    }
    return null;
};

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './ContentGeneratorLoader.styles.scss';
import { WriterLoader } from '~components/Loader';
import { icons } from '~constants';

export type ContentGeneratorLoaderProps = {
    show: boolean;
};

export const ContentGeneratorLoader: React.FC<ContentGeneratorLoaderProps> = ({
    show,
}) => {


    return (
        <Modal
            show={show}
            centered
            contentClassName="modalContent"
            backdrop="static"
            keyboard={false}
        >
            <div className="writer-steps-section content-loader-container">
                <h1 className="step-title d-flex align-items-center justify-content-center">
                    <img src={icons.maticWand} />
                    Hang on, your masterpiece is in the works!
                </h1>
                
                
                   <WriterLoader size='160px'/>
                
            
            </div>
        </Modal>
    );
};

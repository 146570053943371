import React, { FC, useState } from 'react';

import { useAuth } from '~hooks';
import './Header.styles.scss';
import StudybuddyLogoBlack from '../../../assets/studybuddy-logo-black.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { HELP_CENTER_URL } from '~constants';

type HeaderProps = {
    scrollToPricing?: () => void;
};

export const Header: FC<HeaderProps> = ({ scrollToPricing }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { t } = useTranslation();

    const { isAuthenticated } = useAuth();

    const navigate = useNavigate();

    const handleMenuButtonClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const onNavButtonClickHandler = () => {
        if (isAuthenticated) {
            navigate('/dashboard');
        } else {
            navigate('/login');
        }
    };

    const onGetStartedButtonClickHandler = () => {
        navigate('/register');
    };

    return (
        <header className="home-header">
            <div className="header-container">
                <div className="navbarTop">
                    <a href="/">
                        <img
                            src={StudybuddyLogoBlack}
                            alt="Studybuddy Logo"
                            className="navbarBrand"
                        />
                    </a>
                    <button
                        className={isMenuOpen ? 'closeIcon' : 'menuIcon'}
                        onClick={handleMenuButtonClick}
                    >
                        <img
                            src={
                                isMenuOpen
                                    ? '/assets/images/cross_icon.svg'
                                    : '/assets/images/humburger-menu-circled.svg'
                            }
                            alt="menu button"
                        />
                    </button>
                </div>

                <div className="header-links HideOnMobile">
                    <a href="#howItWorks" className="navbarLink">
                        {t('how_it_works')}
                    </a>
                    {/* <a href="#pricing-section" className="navbarLink">
                    {t('try_it_out')}
                </a> */}
                    <HashLink
                        to="/#testimonials-section"
                        className="navbarLink"
                    >
                        {t('reviews')}
                    </HashLink>
                    <a
                        href={HELP_CENTER_URL}
                        className="navbarLink"
                        target="_blank"
                    >
                        {t('help_center')}
                    </a>
                </div>
                <div className="navbarButtons">
                    {!isAuthenticated && (
                        <button
                            onClick={onGetStartedButtonClickHandler}
                            className={`filledButton`}
                        >
                            <a onClick={scrollToPricing}>{t('get_started')}</a>
                        </button>
                    )}
                    <button
                        onClick={onNavButtonClickHandler}
                        className={`outlinedButton ${!isAuthenticated && 'login-transition'}  `}
                    >
                        {isAuthenticated ? t('dashboard') : t('login')}
                    </button>
                </div>

                <nav className={`navbarListMobile ${isMenuOpen ? 'open' : ''}`}>
                    <a
                        href={
                            isAuthenticated ? '/dashboard' : '#pricing-section'
                        }
                        className="navbar-shiny-button btn-primary glow navbarGetStarted"
                    >
                        {isAuthenticated ? t('dashboard') : t('Try For Free')}
                    </a>
                    <button
                        onClick={onNavButtonClickHandler}
                        // className={`${isAuthenticated ? 'full' : ''}`}
                    >
                        <img
                            className="exitIcon"
                            src="/assets/images/exit_icon.svg"
                            alt="exit_icon"
                        />
                        {isAuthenticated ? t('Log Out') : t('login')}
                    </button>
                </nav>
            </div>
        </header>
    );
};

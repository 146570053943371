import React, { useState } from 'react';
import './UpgradeSemester.styles.scss';

import { Assets } from '~constants';
import { useScreenSize, useSnackbar } from '~hooks';
import { useTranslation } from 'react-i18next';
import { semesterPlanUpgrade } from '~components/PaymentPlan';
import {
    emitSemesterUpgradeFromDashboardEvent,
} from '~utils/advertisingEvents';
import { logger } from '~utils';
import SemesterSubscriptionPopup from './SemesterSubscriptionPopup/SemesterSubscriptionPopup';

export const ArrowUp = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="15"
            viewBox="0 0 13 15"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.85341 0.160179L12.853 6.15977C12.9229 6.22969 12.9705 6.31877 12.9898 6.41574C13.0091 6.51271 12.9992 6.61322 12.9613 6.70456C12.9235 6.7959 12.8594 6.87398 12.7772 6.92892C12.695 6.98386 12.5984 7.01319 12.4995 7.01321H9.9997V9.01307C9.99934 9.27816 9.89388 9.53229 9.70643 9.71974C9.51898 9.90719 9.26485 10.0127 8.99977 10.013H4.00011C3.735 10.0127 3.48084 9.90726 3.29339 9.7198C3.10593 9.53234 3.00048 9.27818 3.00018 9.01307V7.01321H0.500348C0.401479 7.01319 0.304837 6.98386 0.222637 6.92892C0.140438 6.87398 0.0763726 6.7959 0.0385402 6.70456C0.000707741 6.61322 -0.00919309 6.51271 0.0100894 6.41574C0.0293718 6.31877 0.0769718 6.22969 0.146872 6.15977L6.14646 0.160179C6.19287 0.113734 6.24797 0.0768883 6.30862 0.0517497C6.36927 0.026611 6.43428 0.0136719 6.49994 0.0136719C6.56559 0.0136719 6.6306 0.026611 6.69126 0.0517497C6.75191 0.0768883 6.80701 0.113734 6.85341 0.160179ZM3.99729 11.0132H8.99695C9.26215 11.0132 9.51649 11.1185 9.70401 11.306C9.89153 11.4935 9.99688 11.7479 9.99688 12.0131V13.013C9.99688 13.2782 9.89153 13.5325 9.70401 13.7201C9.51649 13.9076 9.26215 14.0129 8.99695 14.0129H3.99729C3.7321 14.0129 3.47776 13.9076 3.29024 13.7201C3.10271 13.5325 2.99736 13.2782 2.99736 13.013V12.0131C2.99736 11.7479 3.10271 11.4935 3.29024 11.306C3.47776 11.1185 3.7321 11.0132 3.99729 11.0132Z"
                fill="white"
            />
        </svg>
    );
};

interface UpgradeSemesterProps {
    token: any;
    customerId: any;
}

const UpgradeSemester: React.FC<UpgradeSemesterProps> = (props) => {
    const { token, customerId } = props;
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const { isMobile } = useScreenSize();
    const { infoSnackbar, successSnackbar, errorSnackbar } = useSnackbar();

    const onUpgradeBtnClick = async () => {
        setLoading(true);
        infoSnackbar(`${t('upgrading_sub')}...`);
        const res = await semesterPlanUpgrade(token, customerId);

        logger.info('semester subscription result >> ' + res);

        console.log(res, 'avlnaskvh9whnvi');
        if (res === 'Success') {
            await emitSemesterUpgradeFromDashboardEvent();
            // await emitSemeseterPlanEvent();
            // successSnackbar('Semester subscription started successfully');
            setShowPopup(true);
            // window.location.reload();
        } else {
            errorSnackbar(
                'Something went wrong, try again. This is likely because your bank declined the charge, please try again with a different card.',
            );
            setLoading(false);
        }
    };

    const onClose = () => {
        setShowPopup(false);
        window.location.reload();
    };

    return (
        <div className="upgrade-semester-container">
            {isMobile ? (
                <div className="mobile-image-container">
                    <img
                        src={Assets.upgradeSemrMobile}
                        alt="upgrade-semester"
                    />
                </div>
            ) : (
                <img
                    src={Assets.upgradeSemDesktop}
                    alt="upgrade-semester"
                    className="desktop-discount"
                />
                // <div className="upgrade-semester-discount">Save 30%</div>
            )}

            {/* upgrade plan click */}
            <button onClick={onUpgradeBtnClick} className="upgrade-plan-button">
                <ArrowUp />
                Upgrade to Semester
            </button>
            <SemesterSubscriptionPopup
                showPopup={showPopup}
                handleClose={onClose}
            />
        </div>
    );
};

export default UpgradeSemester;

import React, { useEffect, useState } from 'react';

import { useAuth, useScreenSize } from '~hooks';
import { AppRoutes, CHROME_STORE_EXTENSION_URL, icons } from '~constants';
import { onboardingSteps } from './Onboarding.constants';
import { useExtensionHelper } from '~utils/extension';
import Confetti from '~components/Utility/Confetti';
import { Header } from '~components/Header';
import { OnboardingStep } from './OnboardingStep';

import './Onboarding.styles.scss';
import { useNavigate } from 'react-router-dom';
import { getStripeUserDetails } from '~components/PaymentPlan';
import { useTranslation } from 'react-i18next';

export type OnboardingProps = {
    showOnboarding: boolean;
    setShowOnboarding: (show: boolean) => void;
};

export const Onboarding: React.FC<OnboardingProps> = (
    props: OnboardingProps,
) => {
    const [step, setStep] = useState(1);
    const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
    const [isSubscribedToSemester, setIsSubscribedToSemester] = useState(false);
    const [isTurboModeActive, setIsTurboModeActive] = useState(false);
    const { isMobile, isTablet } = useScreenSize();
    const { user, sendAuthToExtension, planStatus } = useAuth();
    const { t } = useTranslation();

    console.log(
        'is subscribed to semester ------------> ',
        isSubscribedToSemester,
    );
    console.log('is turbo mode active -----------> ', isTurboModeActive);

    console.log('plan status --------------> ', planStatus);

    const navigate = useNavigate();

    const paymentMethodId = user?.data;
    console.log('user data', paymentMethodId);

    useEffect(() => {
        const setUserDetails = async () => {
            let billingData = await getStripeUserDetails(
                user?.data?.signInUserSession?.idToken?.jwtToken,
                String(user?.data?.attributes['custom:stripeCustomerID']),
                t,
            );
            console.log('billing data 48 --------------->', billingData);

            if (
                billingData?.planNickName !== null &&
                billingData?.planNickName !== undefined
            ) {
                if (planStatus?.startsWith('cancels')) {
                    setIsSubscribedToSemester(false);
                } else {
                    setIsSubscribedToSemester(true);
                }
            }
            const now: any = new Date();
            const turboModeExpiryDate =
                user?.data?.signInUserSession?.idToken?.payload[
                    'custom:turbo_expired_at'
                ];
            const targetTime: any = new Date(turboModeExpiryDate);
            const diffInMilliseconds = targetTime - now;
            console.log('turbo mode expiry date', turboModeExpiryDate);
            console.log('diff in milli seconds', diffInMilliseconds);
            if (diffInMilliseconds > 0) {
                setIsTurboModeActive(true);
            }
        };
        setUserDetails();
    }, [user]);


    const handleLeftButtonClick = () => {
        if (step === 1 && !isMobile) {
            window.open(CHROME_STORE_EXTENSION_URL, '_blank');
            setIsExtensionInstalled(true);
            return;
        }
        setStep(step - 1);
    };

    const increaseStep = () => {
        setStep(step + 1);
    };

    const handleRightButtonClick = () => {
        // Skip onboarding if both Turbo and Semester are active
        if (step === 3) {
            if (isTurboModeActive && isSubscribedToSemester) {
                // Close onboarding and show the 'Done' button
                props.setShowOnboarding(false);
                localStorage.setItem('isNewOnboardingCompleted', 'true');
                sendAuthToExtension(); // Signal to retry the auth to the extension
                return;
            }
            if (isSubscribedToSemester && !isTurboModeActive) {
                // Show Turbo Mode modal when Semester is active
                // Trigger the TurboModeCrossSell modal
                setStep(5); // Move to Turbo upsell view
            } else if (isTurboModeActive && !isSubscribedToSemester) {
                // Show Semester upsell when Turbo is active
                // Trigger the SemesterDealUpsell modal
                setStep(4); // Move to Semester upsell view
            }
        } else {
            props.setShowOnboarding(false);
            localStorage.setItem('isNewOnboardingCompleted', 'true');
            sendAuthToExtension();
        }
    };

    const navigateToDashboard = () => {
        navigate(AppRoutes.Dashboard);
    };

    if (step === onboardingSteps.length && !props.showOnboarding) {
        return <Confetti />;
    }

    if (!props.showOnboarding) {
        return null;
    }

    return (
        <div className="onboarding-section">
            {/* <div className="onboarding-page-logo" onClick={navigateToDashboard}>
                <img src={icons.graduationBlack} alt="logo" />
                <h3>
                    Study<span>buddy+</span>
                </h3>
            </div> */}
            {/* <div className="onboarding-header">
                <img src={icons.graduation} alt="logo" />
                <h3>
                    Study<span>buddy+</span>
                </h3>
            </div> */}
            <div className="onboarding-content">
                {(isMobile || isTablet) && (
                    <p className="mobile">
                        <strong>StudyBuddy</strong> isn't compatible with{' '}
                        <strong>iPad, iPhone, or Safari.</strong> It might have
                        issues with lockdown browsers and could be blocked on
                        some school networks. Please use a computer to{' '}
                        <strong>access StudyBuddy</strong>
                    </p>
                )}
                {/* <p
                            className={`steps-tab__step ${
                                onboardingStep.id === step ? 'active' : ''
                            }`}
                            key={onboardingStep.id}
                        >
                            Step {onboardingStep.id}
                        </p> */}

                {step !== 4 && step !== 5 && (
                    <div className="steps-tab">
                        {onboardingSteps.map((onboardingStep) => (
                            <div className="step-container">
                                <div
                                    className={`step-bar ${
                                        onboardingStep.id <= step
                                            ? 'active'
                                            : ''
                                    }`}
                                ></div>
                                {!isMobile && (
                                    <p
                                        className={`step-name ${
                                            onboardingStep.id <= step
                                                ? 'active'
                                                : ''
                                        }`}
                                    >
                                        {onboardingStep.stepName}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                <OnboardingStep
                    step={step}
                    increaseStep={increaseStep}
                    isExtensionInstalled={isExtensionInstalled}
                    onLeftButtonClick={handleLeftButtonClick}
                    onRightButtonClick={handleRightButtonClick}
                    isSubscribedToSemester={isSubscribedToSemester}
                    isTurboModeActive={isTurboModeActive}
                />
            </div>
        </div>
    );
};

import React, { useEffect } from 'react';
import { useAuth, useScreenSize, useSnackbar } from '~hooks';
import TurboCrossSellDesktop from '~/assets/onboarding-turbo-crossSell-desktop.svg';
import TurboCrossSellMobile from '~/assets/onboarding-turbo-crossSell-mobile.svg';
import { useTranslation } from 'react-i18next';
import {
    activateTurboMode,
    getStripeUserDetails,
} from '~components/PaymentPlan';
import {
    emitOnboardingFeedbackEvent,
    emitTurboUpgradeEvent,
} from '~utils/advertisingEvents';

const TurboModeCrossSell = (props: any) => {
    const { onRightButtonClick, increaseStep, isTurboModeActive } = props;
    const { isMobile, isTablet } = useScreenSize();
    const { t } = useTranslation();
    const { user, token, planStatus } = useAuth();
    const { infoSnackbar, successSnackbar, errorSnackbar } = useSnackbar();

    // TODO: Add turbo mode subscription plan logic, if upgraded to turbo, finish onboarding and return to dashboard
    const customerId = user?.data?.attributes['custom:stripeCustomerID'];
    const paymentMethodId = user?.data;
    console.log('user data', paymentMethodId);

    useEffect(() => {
        if (isTurboModeActive) {
            onRightButtonClick();
        }
    }, [isTurboModeActive]);

    const onUpgradeToTurboBtnClick = async () => {
        emitTurboUpgradeEvent();
        const billingData = await getStripeUserDetails(
            user?.data?.signInUserSession?.idToken?.jwtToken,
            String(customerId),
            t,
        );
        console.log('billing data in 26', billingData);
        let turboModeResponse = await activateTurboMode(
            token,
            customerId,
            billingData?.paymentMethodId,
            499,
            'usd',
        );
        console.log('response in cross sell ------->', turboModeResponse);
        if (turboModeResponse.message === 'Turbo mode activated') {
            onRightButtonClick();
            window.location.reload();
        }
    };

    return (
        <div>
            <img
                style={{ objectFit: 'cover' }}
                src={isMobile ? TurboCrossSellMobile : TurboCrossSellDesktop}
                alt="turbo-crossSell"
            />
            <button
                onClick={onUpgradeToTurboBtnClick}
                className="crossSell-btn"
            >
                {'Upgrade'}
            </button>
            <p className="crossSell-warning-text">
                {
                    'Notice: This is a one-time offer. It will NEVER be available again'
                }
            </p>
            <button
                onClick={onRightButtonClick}
                className="crossSell-cancel-btn"
            >
                {'No, I’ll pass on saving time and effort.'}
            </button>
        </div>
    );
};

export default TurboModeCrossSell;

import { Auth } from 'aws-amplify';
import { logger } from '~utils';
import { SubmitPasswordFormData } from './SubmitPassword';

export const forgotPassword = async (email: string) => {
    try {
        const data = await Auth.forgotPassword(email.toLowerCase());
        return data;
    } catch (err: any) {
        logger.error(err);
        return null;
    }
};

// Collect confirmation code and new password
export const forgotPasswordSubmit = async (
    email: string,
    code: string,
    newPassword: string,
    confirmNewPassword?: string,
) => {
    try {
        const data = await Auth.forgotPasswordSubmit(
            email.toLowerCase(),
            code,
            newPassword,
            // confirmNewPassword
        );
        return data;
    } catch (err: any) {
        logger.error(err);
        throw err;
    }
};

export const formValidation = (formData: SubmitPasswordFormData) => {
    const { email, password, confirmPassword, code } = formData;
    const errors = {
        email: '',
        password: '',
        code: '',
        confirmPassword: '',
    };
    if (!email) {
        errors.email = 'Email is required';
    }
    if (!code) {
        errors.code = 'Code is required';
    }
    if (!password) {
        errors.password = 'Password is required';
    }
    if (password && !confirmPassword) {
        errors.confirmPassword = 'Confirm Password is required';
    }
    if (password && password.length < 8) {
        errors.password = 'Password must be at least 8 characters';
    }
    if (confirmPassword && confirmPassword.length < 8) {
        errors.password = 'confirm Password must be at least 8 characters';
    }
    if (
        password &&
        confirmPassword &&
        password.trim() !== confirmPassword?.trim()
    ) {
        errors.confirmPassword = 'Passwords do not match.';
    }
    if (Object.values(errors).some((error) => error !== '')) {
        return errors;
    }
    return null;
};

import React, { useMemo, useState } from 'react';
import BlurBackdropPopup from '~components/Modals/BlurBackdropPopup/BlurBackdropPopup';
import { ReactComponent as NinjaIcon } from '~/assets/ninja-icon.svg';
import { ContentTextArea } from '~components/ContentTextArea/ContentTextArea';
import CrossIcon from '~/assets/cross.svg';

import './CheckForAi.style.scss';
import AiDetectorFooter from '~pages/WriterDashboard/AiDetector/AiDetectorFooter/AiDetectorFooter';
import { AiDetectionResponse } from '~pages/WriterDashboard/AiDetector/WriterAIDetector';
import { useTranslation } from 'react-i18next';

interface CheckForAiProps {
    showPopup: boolean;
    text: string;
    handleClose: () => void;
    onTextUpdate: React.Dispatch<React.SetStateAction<string>>;
    onHumanizeText: () => void;
    isHighlighted: boolean;
    aiDetectionResponse?: AiDetectionResponse;
}

const CheckForAi: React.FC<CheckForAiProps> = ({
    showPopup,
    handleClose,
    onTextUpdate,
    text,
    onHumanizeText,
    isHighlighted,
    aiDetectionResponse,
}) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const status = useMemo(() => {
        const humanPercent = aiDetectionResponse?.msg;
        if (humanPercent === undefined) return 'NONE';

        if (humanPercent > 70) {
            return 'UNDETECTED';
        } else if (humanPercent > 50) {
            return 'WARNING';
        } else {
            return 'DETECTED';
        }
    }, [aiDetectionResponse]);

    const percentage = useMemo(() => {
        if (aiDetectionResponse?.msg !== undefined) {
            const formattedProbability =
                Math.round(100 -aiDetectionResponse.msg);
            return formattedProbability;
        }

        return 0;
    }, [aiDetectionResponse]);

    const { t } = useTranslation();
    return (
        <BlurBackdropPopup
            show={showPopup}
            handleClose={handleClose}
            customClass="check-for-ai-width"
        >
            <div className="checkAiContainer">
                <div className="closePopup">
                    <img
                        onClick={handleClose}
                        src={CrossIcon}
                        height={'25px'}
                        width={'25px'}
                        alt="close"
                    />
                </div>
                <div className="checkAiHeader">
                    <span> {t('writer.waiting_for_input')} </span>
                    <button className="humanizeButton" onClick={onHumanizeText}>
                        <NinjaIcon />
                        {t('writer.humanize_button')}
                    </button>
                </div>

                <div className="checkAiBody">
                    <ContentTextArea
                        onTextChange={onTextUpdate}
                        isInDetectMode={true}
                        isHighlighted={isHighlighted}
                        text={text}
                        aiDetectionResponse={aiDetectionResponse}
                        isEditing={isEditing} 
                        setIsEditing={setIsEditing}
                    />
                </div>
                <div className="checkAiFooter">
                    <AiDetectorFooter
                        percentage={percentage}
                        status={status}
                        whiteBg={true}
                    />
                </div>
            </div>
        </BlurBackdropPopup>
    );
};

export default CheckForAi;

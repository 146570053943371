const AvatarIcon = ({ initial }: { initial: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle
                cx="42.25"
                cy="42.25"
                r="7.71875"
                fill="white"
                stroke="#EBEBEB"
                stroke-width="0.8125"
            />
            <path
                d="M37.5677 40.0052C37.6961 39.8767 37.8482 39.8125 38.024 39.8125C38.1994 39.8125 38.3513 39.8767 38.4797 40.0052L42.2436 43.769L46.0203 39.9923C46.1402 39.8724 46.29 39.8125 46.4699 39.8125C46.6497 39.8125 46.8039 39.8767 46.9323 40.0052C47.0608 40.1336 47.125 40.2857 47.125 40.4615C47.125 40.6369 47.0608 40.7888 46.9323 40.9172L42.6033 45.2334C42.5519 45.2848 42.4962 45.3213 42.4363 45.3429C42.3763 45.3641 42.3121 45.3748 42.2436 45.3748C42.1751 45.3748 42.1108 45.3641 42.0509 45.3429C41.9909 45.3213 41.9353 45.2848 41.8839 45.2334L37.5548 40.9044C37.4349 40.7845 37.375 40.6369 37.375 40.4615C37.375 40.2857 37.4392 40.1336 37.5677 40.0052Z"
                fill="#71717A"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M49.9136 36.2218C51.2565 33.0842 52 29.6289 52 26C52 11.6406 40.3594 0 26 0C11.6406 0 0 11.6406 0 26C0 40.3594 11.6406 52 26 52C29.6289 52 33.0842 51.2565 36.2218 49.9136C33.9553 48.1283 32.5 45.3591 32.5 42.25C32.5 36.8652 36.8652 32.5 42.25 32.5C45.3591 32.5 48.1283 33.9553 49.9136 36.2218Z"
                fill="#3D5AF1"
            />
            <text
                id="initial"
                x="26"
                y="30"
                font-size="24"
                fill="white"
                text-anchor="middle"
                dominant-baseline="middle"
                className="initial"
            >
                {initial}
            </text>
        </svg>
    );
};

export default AvatarIcon;

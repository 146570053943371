import React, { useEffect, useState } from 'react';
import { WriterSteps } from './WriterSteps';
import './WriterDashboard.styles.scss';
import WriterTools from './WriterTools/WriterTools';
import { useTranslation } from 'react-i18next';
import { icons } from '~constants';

export const WriterDashboard = () => {
    useEffect(() => {
        (window as any).botpressWebChat.mergeConfig({ hideWidget: true });
    }, []);
    const { t } = useTranslation();
    return (
        <div>
            <header>
                <h1 className="dashboard-title d-flex">
                    <img src={icons.wavingHand} />
                    {t('writer.heading')}
                </h1>
            </header>
            <div className="dashboard-container">
                <WriterTools />
            </div>
        </div>
    );
};

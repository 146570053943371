import { useEffect, useRef } from 'react';
import ContentTextAreaInput from './ContentTextAreaInput';
import TextAreaAction from './TextAreaAction';

import pencilIcon from '~/assets/pencil.svg';
import pasteIcon from '~/assets/paste.svg';
import uploadIcon from '~/assets/upload.svg';

import './ContentTextArea.styles.scss';
import { readFileData } from '~utils/getPdfContent';
import ContentEditableDiv from './ContentEditableDiv';
import { AiDetectionResponse } from '~pages/WriterDashboard/AiDetector/WriterAIDetector';
import { useTranslation } from 'react-i18next';

interface ContentTextAreaProps {
    onTextChange: React.Dispatch<React.SetStateAction<string>>;
    gapClass?: string;
    text: string;
    isInDetectMode?: boolean;
    isHighlighted?: boolean;
    aiDetectionResponse?: AiDetectionResponse;
    isEditing: boolean;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ContentTextArea: React.FC<ContentTextAreaProps> = ({
    onTextChange,
    gapClass,
    isInDetectMode,
    isHighlighted,
    text,
    aiDetectionResponse,
    isEditing,
    setIsEditing,
}) => {
    // const [isEditing, setIsEditing] = useState<boolean>(!!text || !!isEditingParent);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { t } = useTranslation();
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        if (text.length > 0 && value.length === 0) {
            setIsEditing(false);
        }
        onTextChange(value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onTextChange((prevText) => `${prevText}\n`);
        }
    };

    const initializeEditing = (text: string = '') => {
        setIsEditing(true);
        onTextChange(text);
        setTimeout(() => {
            if (textareaRef.current) {
                textareaRef?.current?.focus();
                textareaRef?.current?.setSelectionRange(
                    text.length,
                    text.length,
                );
            }
        }, 100);
    };
    const handlePasteText = async () => {
        try {
            const text = await navigator.clipboard.readText();
            initializeEditing(text);
        } catch (err) {
            console.log(err);
        }
    };
    const handleUploadFile = () => {
        fileInputRef.current?.click();
    };

    const onStartTyping = () => {
        initializeEditing();
    };

    const handleReadFile = async (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        try {
            // Check if the file input has files
            if (!event.target.files || event.target.files.length === 0) {
                throw new Error('No files selected.');
            }

            // Assuming readFileData is an async function
            const content = await readFileData(event.target.files[0]);
            initializeEditing(content);
        } catch (error) {
            console.error('Error reading file:', error);
        }
    };

    const onClickOutside = () => {
        if (!text.length) {
            setIsEditing(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: globalThis.MouseEvent) => {
            if (
                textareaRef.current &&
                !textareaRef.current.contains(event?.target as Node)
            ) {
                onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);

    useEffect(() => {
        if (!text) {
            setIsEditing(false);
        }
    }, [text]);

    // useEffect(() => {
    //     if (!!isEditingParent) {
    //         setIsEditing(true);
    //     }
    // }, [isEditingParent]);

    return (
        <>
            {isInDetectMode ? (
                <ContentEditableDiv text={text} isHighlighted={isHighlighted} />
            ) : isEditing ? (
                <ContentTextAreaInput
                    text={text}
                    onChange={handleChange}
                    ref={textareaRef}
                    handleKeyDown={handleKeyDown}
                />
            ) : (
                <>
                    <p className="ai-text-header">
                        {t('writer.ai_detector_placeholder')}
                    </p>
                    <div className={`contentTextAreaActions ${gapClass}`}>
                        <TextAreaAction
                            icon={pencilIcon}
                            label="Start Typing"
                            action={onStartTyping}
                        />
                        <TextAreaAction
                            icon={pasteIcon}
                            label="Paste Text"
                            action={handlePasteText}
                        />
                        <TextAreaAction
                            icon={uploadIcon}
                            label=" Upload File"
                            action={handleUploadFile}
                        />
                    </div>
                </>
            )}
            <input
                ref={fileInputRef}
                type="file"
                accept="application/pdf, .docx, .txt"
                style={{ display: 'none' }}
                onChange={handleReadFile}
            />
        </>
    );
};

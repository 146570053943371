import React, { useEffect, useRef, useState } from 'react';

// import { icons } from '~constants';
import { useAuth, useScreenSize } from '~/hooks';
import StudybuddyLogoBlack from '../../../assets/studybuddy-logo-black.svg';
import './WelcomeHeader.styles.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AvatarPopup } from './AvatarPopup/AvatarPopup';
import AvatarIcon from './AvatarIcon';
export const DashboardHeader = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const { user } = useAuth();
    const { logout } = useAuth();
    const { isMobile } = useScreenSize();
    const navigate = useNavigate();
    const openPopupRefDesktop = useRef(null);
    const openPopupRefMobile = useRef(null);

    const getInitial = () => {
        const nameInitial = user?.data?.attributes.given_name
            ?.charAt(0)
            .toLocaleUpperCase();
        const emailInitial = user?.data?.attributes.email
            ?.charAt(0)
            .toLocaleUpperCase();
        return nameInitial || emailInitial;
    };

    const { t } = useTranslation();

    const handleMenuButtonClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const homeClickHandler = () => {
        navigate('/');
    };
    const logoutClickHandler = () => {
        logout();
    };

    const handlePopupOpenToggle = () => {
        setShowPopup((prev) => !prev);
    };

    console.log(user, 'helll');

    return (
        <nav className="dashboard-header">
            <div className="dashboard-header-container">
                <div className="dashboard-navbarTop">
                    <a href="/">
                        <img
                            src={StudybuddyLogoBlack}
                            alt="Studybuddy Logo"
                            className="dashboard-navbarBrand"
                        />
                    </a>

                    <div
                        className="avatar-container mobile "
                        onClick={handlePopupOpenToggle}
                        ref={openPopupRefMobile}
                    >
                        <AvatarIcon initial={getInitial()} />
                    </div>
                </div>

                <div className="dashboard-navbarButtons">
                    <div
                        className="avatar-container"
                        onClick={handlePopupOpenToggle}
                        ref={openPopupRefDesktop}
                    >
                        <AvatarIcon initial={getInitial()} />
                    </div>
                </div>
            </div>
            <AvatarPopup
                anchorEl={
                    isMobile
                        ? openPopupRefMobile.current
                        : openPopupRefDesktop.current
                }
                isOpen={showPopup}
                onClose={() => setShowPopup(false)}
            />
        </nav>
    );
};

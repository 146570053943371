import React, { useState, useRef, useEffect, CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as UserIcon } from '~/assets/user.svg';
import { ReactComponent as LogoutIcon } from '~/assets/log-out_dashboard.svg';
import './AvatarPopup.styles.scss';
import { useAuth, useScreenSize } from '~hooks';
import AccountInfoPopup from '../AccountInfoPopup/AccountInfoPopup';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    anchorEl: HTMLDivElement | null;
}

export const AvatarPopup = ({ isOpen, anchorEl, onClose }: Props) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const { isMobile } = useScreenSize();
    const { logout } = useAuth();

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => setIsVisible(true), 10);
        } else {
            setIsVisible(false);
        }
    }, [isOpen]);

    if (!isOpen || !anchorEl) {
        return null;
    }

    console.log(anchorEl.offsetWidth, 'window.scrollY');

    const top = isMobile
        ? '90px'
        : `${anchorEl.getBoundingClientRect().bottom + 10}px`;

    const left = dropdownRef.current
        ? `${anchorEl.getBoundingClientRect().left - dropdownRef.current.offsetWidth + anchorEl.offsetWidth}px`
        : '0px';

    const dropdownStyle: CSSProperties = {
        position: 'absolute',
        top,
        left,
        zIndex: 3,
        display: 'inlineFlex',
        padding: '8px',
        flexDirection: 'column',
        alignItems: 'flexStart',
        borderRadius: '10px',
        background: '#FFF',
        boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.10)',
    };

    const overlayStyle: CSSProperties = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 2,
    };

    return ReactDOM.createPortal(
        <>
            <div
                style={overlayStyle}
                onClick={onClose}
                className={`avatar-dropdown-overlay ${isVisible ? 'open' : ''}`}
            ></div>
            <div
                ref={dropdownRef}
                style={dropdownStyle}
                className={`avatar-dropdown-menu ${isVisible ? 'open' : ''}`}
            >
                <div className="avatar-popup-option">
                    <span
                        onClick={() => {
                            logout();
                        }}
                    >
                        <LogoutIcon />
                        Log Out
                    </span>
                </div>
                <div className="avatar-popup-option">
                    <span
                        onClick={() => setShowPopup(true)}
                        className="user-icon-container"
                    >
                        {' '}
                        <UserIcon /> Update Account Info
                    </span>
                </div>
            </div>
            <AccountInfoPopup
                handleClose={() => {
                    setShowPopup(false);
                }}
                showPopup={showPopup}
            />
        </>,
        document.body,
    );
};

import { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth, useSnackbar } from '~hooks';

import {
    fetchHumanizedText,
    fetchAIDetection,
    getWordCount,
    MIN_WORDS_FOR_HUMANIZE,
    MIN_WORDS_FOR_AI_CHECK,
} from '../WriterTextHumanizer.util';
import { AiDetectionResponse } from '~pages/WriterDashboard/AiDetector/WriterAIDetector';
import { defaultErrorMessage } from '~constants';

export function useManageTextHumanizer() {
    const { state } = useLocation();
    const { t } = useTranslation();
    const { token } = useAuth();
    const { errorSnackbar } = useSnackbar();

    const [text, setText] = useState<string>(state?.payloadText || '');
    const [humanizedText, setHumanizedText] = useState<string>('');
    const [isEditing, setIsEditing] = useState<boolean>(!!state?.payloadText);

    const [loadingStates, setLoadingStates] = useState({
        isHumanizing: false,
        isAIChecking: false,
    });

    const [aiCheckState, setAiCheckState] = useState({
        showPopup: false,
        isDetected: false,
        aiDetectionResponse: undefined as AiDetectionResponse | undefined,
        checkedText: '',
    });

    const wordCount = useMemo(() => getWordCount(text), [text]);

    /**
     * @description this method will humanize the text that is passed in it
     */
    const handleHumanizeText = async (textToHumanize: string) => {
        if (getWordCount(textToHumanize) < MIN_WORDS_FOR_HUMANIZE) {
            errorSnackbar(
                t('writer.min_words_error', { count: MIN_WORDS_FOR_HUMANIZE }),
            );
            return;
        }
        setLoadingStates((prev) => ({ ...prev, isHumanizing: true }));
        try {
            const result = await fetchHumanizedText(
                textToHumanize,
                token as string,
            );
            setHumanizedText(result);
        } catch (error:any) {
            errorSnackbar(error?.response?.data?.msg || defaultErrorMessage);
        } finally {
            setLoadingStates((prev) => ({ ...prev, isHumanizing: false }));
        }
    };

    /**
     * @description this method will detect the text that is passed in it
     */
    const handleAIDetection = async (textToCheck: string) => {
        if (getWordCount(textToCheck) < MIN_WORDS_FOR_AI_CHECK) {
            errorSnackbar(
                t('writer.min_words_error', { count: MIN_WORDS_FOR_AI_CHECK }),
            );
            return;
        }
        setLoadingStates((prev) => ({ ...prev, isAIChecking: true }));
        setAiCheckState((prev) => ({ ...prev, checkedText: textToCheck }));
        try {
            const result = await fetchAIDetection(textToCheck, token as string);
            setAiCheckState((prev) => ({
                ...prev,
                aiDetectionResponse: result,
                isDetected: result.msg < 70,
                showPopup: true,
            }));
        } catch (error:any) {
            errorSnackbar(error?.response?.data?.msg || defaultErrorMessage);
        } finally {
            setLoadingStates((prev) => ({ ...prev, isAIChecking: false }));
        }
    };

    /**
     * check which text is getting detected - `original` | `humanized`
     */
    const isCheckedTextDetecting = (currentText: string) =>
        aiCheckState.checkedText === currentText && loadingStates.isAIChecking;

    return {
        // memoized value
        wordCount,
        // methods for handling
        handleAIDetection,
        handleHumanizeText,
        isCheckedTextDetecting,

        // single states
        humanizedText,
        text,
        // object states
        ...loadingStates,
        ...aiCheckState,

        // state setters
        setText,
        setAiCheckState,
        isEditing,
        setIsEditing
    };
}

import { QRCodeSVG } from 'qrcode.react';

interface QrCodeProps {
  redirectUrl:string;
  size:number;
}

const QRCode: React.FC<QrCodeProps> = ({
  redirectUrl,
  size
}) => {
    return (
        <QRCodeSVG value={redirectUrl} size={size} level="H" />
    );
  };

export default QRCode
import React from 'react';
import { ReactComponent as NinjaIcon } from '~/assets/ninja-icon.svg';
import './AiDetectorHeader.styles.scss';
import { Loader } from '~components/Loader';
import { useTranslation } from 'react-i18next';

interface AiDetectorHeaderProps {
    text?: string;
    isInDetectMode: boolean;
    setIsInDetectMode: React.Dispatch<React.SetStateAction<boolean>>;
    handleAction: (button: string) => void;
    isLoading: boolean;
    setText?: React.Dispatch<React.SetStateAction<string>>;
}

export function AiDetectorHeader({
    isInDetectMode,
    setIsInDetectMode,
    handleAction,
    text,
    isLoading,
    setText
}: Readonly<AiDetectorHeaderProps>) {
    const { t } = useTranslation();
    const humanizeButtonText = isInDetectMode
        ? t('writer.humanize_button')
        : t('writer.detect_ai');

    return (
        <div className="ai-detector-header">
            {isInDetectMode ? (
                <button
                    className="backButton"
                    disabled={!isInDetectMode}
                    onClick={() => {
                        setIsInDetectMode(false);
                        setText && setText('')
                    }}
                >
                    {t('Back')}
                </button>
            ) : (
                <div />
            )}
            <button
                className="humanizeButton"
                disabled={!text}
                onClick={() => handleAction(humanizeButtonText)}
            >
                {isLoading ? (
                    <Loader color="#fff" />
                ) : (
                    <>
                        <NinjaIcon />
                        {humanizeButtonText}
                    </>
                )}
            </button>
        </div>
    );
}
